const CommonServerRoute = {
    live_account: 'accounts.themunim.com',
    dev_account: 'devaccounts.themunim.com',
    stage_account: 'stageaccounts.themunim.com',
    qa_account: 'qaaccounts.themunim.com',
    dev_gst: 'devgst.themunim.com',
    dev_app: 'dev.themunim.com',
    dev_ecom: 'devecom.themunim.com',
    stage_app: 'stage.themunim.com',
    stage_gst: 'stagegst.themunim.com',
    live_gst: 'gst.themunim.com',
    live_app: 'app.themunim.com',
    qa_app:'qa.themunim.com',
    live_ecom: 'ecommerce.themunim.com',
}
export default CommonServerRoute
