import React from 'react'
// ** Reactstrap Imports
import { Input, Label } from 'reactstrap'

const InputCheckBoxField = ({ value, placeholder, name, onChange, checked, onBlur, id, disabled, defaultChecked, label, onFocus, className, paddingUnset }) => {
    return (
        <>
            <Input
                className={className}
                value={value}
                placeholder={placeholder}
                name={name}
                id={id}
                onChange={!disabled ? (e) => { onChange(e) } : () => { }}
                onBlur={onBlur}
                onFocus={onFocus}
                autoComplete='true'
                type='checkbox'
                defaultChecked={defaultChecked}
                checked={checked}
                disabled={disabled}
                role='button'
            />
            {label ? <Label onClick={!disabled ? (e) => { e.preventDefault(); onChange(e) } : () => { }} for={id} className={` form-check-label ${paddingUnset}`}>
                {label}
            </Label> : ''}
        </>
    )
}

export default InputCheckBoxField
