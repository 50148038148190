import axios from "axios"
import CommonServerRoute from "./commonServerRoute"
import { product } from "./commonApi"

const getUserIpAddress = async () => {
    if (localStorage.getItem('user_ip')) {
        return localStorage.getItem('user_ip')
    } else {
        const user_ip = await axios.get('https://api.bigdatacloud.net/data/client-ip').then(response => { return response.data.ipString }).catch(() => '')
        localStorage.setItem('user_ip', user_ip)
        return user_ip
    }
}
function debounce(fn, time) {
    let timer
    return function (...args) {
        clearTimeout(timer)
        timer = setTimeout(() => {
            fn(...args)
        }, time)
    }
}

function getCookie(key) {
    const match = document.cookie.match(new RegExp(`(^| )${key}=([^;]+)`))
    if (match) return match[2]
}

function makeRedirectUrl(res = {}, today_date = '', user_email = {}, redirect_to) {
    const current_time = Date.parse(new Date(today_date))
    const redirect_url = product[redirect_to]
    const create_url = `${product[redirect_to]}?response_data=${btoa(JSON.stringify({ _ett: current_time + 10800000, access_token: res.data?.data.access_token, refresh_tokens: res.data?.data.refresh_tokens, userData: JSON.stringify({ ...res.data?.data, email: user_email?.email }) }))}`
    return { create_url, redirect_url }
}

function getUrlDomain() {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('product')
        const decode_data = myParam ? myParam : ''
        if ((decode_data === 'app')) {
            return 1
        } else if ((decode_data === 'gst')) {
            return 2
        } else if ((decode_data === 'ecom')) {
            return 3
        } else {
            return 0;
        }
    } catch (error) {

    }
}

function getLoginDomain() {
    try {
        const login_domain = window.location.host
        if (login_domain === CommonServerRoute.live_account) {
            return 1
        } else {
            return 0;
        }
    } catch (error) {

    }
}

const detectBrowser = () => {
    const nAgt = navigator.userAgent
    let browserName = navigator.appName
    let nameOffset, verOffset
    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
        browserName = "Opera"
    } else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
        // In MSIE, the true version is after "MSIE" in userAgent
        browserName = "Microsoft Internet Explorer"
    } else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
        browserName = "Edge"
    } else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
        // In Chrome, the true version is after "Chrome" 
        browserName = "Chrome"
    } else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
        // In Safari, the true version is after "Safari" or after "Version" 
        browserName = "Safari"
    } else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
        // In Firefox, the true version is after "Firefox" 
        browserName = "Firefox"
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        // In most other browsers, "name/version" is at the end of userAgent 
        browserName = nAgt.substring(nameOffset, verOffset)
    }
    return browserName
}

export { getUserIpAddress, debounce, getCookie, makeRedirectUrl, getUrlDomain, getLoginDomain, detectBrowser }