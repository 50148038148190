import React, { useEffect, useState } from 'react'
import { Button, Col, InputGroup, InputGroupText, Label, Row } from 'reactstrap'
import gstLogin from '../assets/img/gst-login.svg'
import appLogin from '../assets/img/app-login.svg'
import gstLogo from '../assets/img/logo.svg'
import logoUtility from '../assets/img/munim-gst-logo.svg'
import CustomButton from '../common_component/custom_field/CustomButton'
import InputNumberField from '../common_component/custom_field/InputNumberField'
import InputPasswordField from '../common_component/custom_field/InputPasswordField'
import * as Yup from "yup"
import { useFormik } from "formik"
import ValidationMessage from '../common_component/Validation'
import { getCookie, getUserIpAddress, makeRedirectUrl, getUrlDomain, getLoginDomain, detectBrowser } from '../helper/commonFunction'
import useNotify from '../custom_hooks/useNotify'
import { ApiCall } from '../helper/axios'
import CommonApiEndPoint from '../helper/commonApiEndPoint'
import { useHistory, useLocation, Link, useParams } from 'react-router-dom'
import CommonRouter from '../helper/commonRoute'
import { apiEndpoint, product } from '../helper/commonApi'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Hotkeys from 'react-hot-keys'
import FallbackSpinner from '../common_component/spinner/FallbackSpinner'
import InputCheckBoxField from '../common_component/custom_field/InputCheckBoxField'
import SignUpRedirectModal from '../common_component/pop_up_modal/SignUpRedirectModal'
import CommonServerRoute from '../helper/commonServerRoute'
function SignUp() {
    const notify = useNotify()
    const { token } = useParams()
    const history = useHistory()
    const { search } = useLocation()
    const [mobileResendOtpRemainingTime, setMobileOtpRemainingTime] = useState(60)
    const [finalSchema, setFinalSchema] = useState()
    const [step, setStep] = useState(1)
    const [mobileOtpResendDisable, setMobileOtpResendDisable] = useState(false)
    const [mobileVerified, setMobileVerified] = useState(false)
    const [getOtpLoader, setGetOtpLoader] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [loader, setLoader] = useState(false)
    const [utmString, setUtmString] = useState('')
    const [referralId, setReferralId] = useState('')
    const [incompleteData, setIncompleteData] = useState([])
    const [showPassword, setShowPassword] = useState(false)
    const [redirectPopUp, setRedirectPopUp] = useState(false)
    const [productName, setProductName] = useState('')
    let timer

    const countDown = (time) => {
        let remaining_time = time
        let final_timer = timer;
        final_timer = setInterval(() => {
            remaining_time -= 1
            setMobileOtpRemainingTime(remaining_time)
            if (remaining_time < 1) {
                clearInterval(final_timer)
            }
        }, 1000)
    }

    const firstStepSchema = {
        mobile: Yup.string().trim()
            .matches(/^[6-9]\d{9}$/gi, ValidationMessage.mn_not_valid)
            .required(ValidationMessage.is_require),
    }
    const secondStepSchema = {
        mobile_otp: Yup.string().trim()
            .required(ValidationMessage.otp_req)
            .min(6, ValidationMessage.otp_min_max)
            .max(6, ValidationMessage.otp_min_max),
    }
    const passwordSchema = {
        password: Yup.string().trim()
            .min(6, ValidationMessage.signup_valid_password)
            .required(ValidationMessage.is_require),
    }
    useEffect(() => {
        if (step === 1) {
            setFinalSchema(Yup.object().shape({ ...firstStepSchema }))
        } else if (step === 2 || step === 3) {
            setFinalSchema(Yup.object().shape({ ...firstStepSchema, ...secondStepSchema }))
        }
        if (step !== 1 && showPassword) {
            setFinalSchema(Yup.object().shape({ ...firstStepSchema, ...secondStepSchema, ...passwordSchema }))
        }
    }, [step, showPassword])
    /**
     * IW0079
     * This function is called when user click on get otp or click on resend otp button.
     */
    const generateOtp = async (action, otpType) => {
        if (action === 2) {
            if (otpType === 1) {
                setMobileOtpResendDisable(true)
            }
        }
        const ip_address = await getUserIpAddress()
        const get_domain_enum = await getUrlDomain()

        const data = {
            mobile: formik.values.mobile,
            action: 1,
            registered_app: get_domain_enum
        }
        if (action === 2) {
            data.otp_type = otpType
        }
        const header = { ip: ip_address }
        if ((step === 1 && !otpType) || (step === 2 && otpType)) {
            const res = await ApiCall("POST", CommonApiEndPoint.generate_user_otp, data, header, 40000)
            if (res.data.status === "success" && res.data.statusCode === 200) {
                setStep(2)
                const url_domain = getLoginDomain()
                setGetOtpLoader(false)
                if (url_domain !== 0) {
                    if (action === 1 || otpType === 1) {
                        countDown(60)
                        setTimeout(() => {
                            setMobileOtpResendDisable(false)
                            setMobileOtpRemainingTime(60)
                        }, 60000)
                        formik.setFieldTouched('mobile_otp', false)
                    }
                } else {
                    formik.setFieldValue('mobile_otp', res.data.data.mobile_otp)
                    setMobileVerified(true)
                    verifyOTP(res.data.data.mobile_otp)
                }
                notify(res.data.message, 'success')
                setFormSubmitted(false)
                formik.setSubmitting(false)
            } else {
                setMobileOtpResendDisable(false)
                formik.setSubmitting(false)
                setGetOtpLoader(false)
                setFormSubmitted(false)
                notify(res.data.message, 'error')
            }
        }
    }

    /**
     * This function is called to log-in on user sign-up
     */
    const handleSignInAfterSignUp = async (res, urlRedirect) => {
        if (detectBrowser() === 'Safari') {
            const hostname = window.location.hostname
            if (productName === 'gst') {
                if (hostname === 'stageaccounts.themunim.com') {
                    window.open('https://stagegst.themunim.com/log-in', '_self')
                } else if (hostname === 'devaccounts.themunim.com') {
                    window.open('https://devgst.themunim.com/log-in', '_self')
                } else if (hostname === 'accounts.themunim.com') {
                    window.open('https://gst.themunim.com/log-in', '_self')
                } else {
                    window.open('http://localhost:4242/log-in', '_self')
                }
            } else if (productName === 'ecom') {
                if (hostname === 'devaccounts.themunim.com') {
                    window.open('https://devecom.themunim.com/log-in', '_self')
                } else if (hostname === 'accounts.themunim.com') {
                    window.open('https://ecommerce.themunim.com/log-in', '_self')
                } else {
                    window.open('http://localhost:5555/log-in', '_self')
                }
            } else {
                if (hostname === 'devaccounts.themunim.com') {
                    window.open('https://dev.themunim.com/log-in', '_self')
                } else if (hostname === 'stageaccounts.themunim.com') {
                    window.open('https://stage.themunim.com/log-in', '_self')
                } else if (hostname === 'qaaccounts.themunim.com') {
                    window.open('https://qa.themunim.com/log-in', '_self')
                } else if (hostname === 'accounts.themunim.com') {
                    window.open('https://app.themunim.com/log-in', '_self')
                } else {
                    window.open('http://localhost:3000/log-in', '_self')
                }
            }
        } else {
            setLoader(true)
            setRedirectPopUp(false)
            const current_time = Date.parse(new Date(new Date()))
            localStorage.setItem('is_log', true)
            localStorage.setItem('common_data', JSON.stringify({ _ett: current_time + 10800000, access_token: res.data.data.access_token, refresh_tokens: res.data.data.refresh_tokens, userData: JSON.stringify({ ...res.data.data }) }))
            const final_redirect_url = makeRedirectUrl(res, new Date(), res.data.data, productName)
            if (urlRedirect) {
                /*SELECT POPUP OPTION AND REDIRECT THE DOMAIN */
                localStorage.setItem('logged_app', urlRedirect)
                window.open(`${urlRedirect}`, '_parent')
            } else if (token) {
                /*THIS IS WORKING ON TOKEN GET IN URL */
                localStorage.setItem('logged_app', final_redirect_url.redirect_url)
                window.open(`${final_redirect_url.create_url}${token && token}`, '_parent')
            } else {
                localStorage.setItem('logged_app', `https://${CommonServerRoute.live_app}/`)
                window.open(`https://${CommonServerRoute.live_app}/`, '_parent')
            }
        }
    }
    const handleUserSignUP = async (value, redirect_flag) => {
        setGetOtpLoader(true)
        if (step === 1) {
            setMobileOtpResendDisable(true);
            generateOtp(1)
        } else if (step === 3 && formik.values.terms_conditions === false) {
            formik.setSubmitting(false)
            setFormSubmitted(false)
            notify('Please accept for terms & conditions!', 'error')
        } else {
            const ip_address = await getUserIpAddress()
            const get_domain_enum = await getUrlDomain()
            setLoader(true)
            const data = {
                mobile: value.mobile,
                utm_tag: utmString ? utmString : getCookie('utm_tag'),
                referral: localStorage.getItem('_referral'),
                registered_app: get_domain_enum
            }
            if (value.password.length) data.password = value.password;
            const header = { ip: ip_address }
            const res = await ApiCall("POST", CommonApiEndPoint.sign_up, data, header)
            if (res.data.status === "success" && res.data.statusCode === 200) {
                notify(res.data.message, 'success')
                let urlRedirect
                if (incompleteData?.length) {
                    if (incompleteData.registered_app === '1') {
                        urlRedirect = `https://${CommonServerRoute.live_app}/`
                    } else if (incompleteData.registered_app === '2') {
                        urlRedirect = `https://${CommonServerRoute.live_gst}/`
                    } else {
                        urlRedirect = `https://${CommonServerRoute.live_app}/`
                    }
                } else {
                    urlRedirect = product[productName]
                }
                handleSignInAfterSignUp(res, urlRedirect)
                setGetOtpLoader(false)
                // history.replace(redirect_url)
                localStorage.removeItem('_referral')
            } else {
                formik.setSubmitting(false)
                setFormSubmitted(false)
                notify(res.data.message, 'error')
                setLoader(false)
                setGetOtpLoader(false)
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            password: "",
            mobile: "",
            mobile_otp: "",
            terms_conditions: true
        },
        validationSchema: finalSchema,
        onSubmit: (value) => {
            if (window.location.search || step === 1) {
                handleUserSignUP(value)
            } else if (mobileVerified === true) {
                handleUserSignUP(value)   // GST live then  remove this line 
                // iw0214  start  GST utility live  then uncommit 
                // setRedirectPopUp(true)
                // iw0214  end  GST utility live  then uncommit 
            }
        }
    })
    const onKeyDown = (keyName, e) => {
        e.preventDefault()
        if (keyName === 'enter') {
            if (!formSubmitted) {
                setFormSubmitted(true)
            } else {
                if (!mobileVerified && step === 2) {
                    document.getElementById('mobile_otp').focus()
                } else if (Object.keys(formik?.errors).length) {
                    const error_id = Object.keys(formik.errors)[0]
                    document.getElementById(error_id).focus()
                }
            }
        }
    }
    const handleSignUpAndGetOTP = () => {
        if (!formSubmitted) {
            setFormSubmitted(true)
        }
        else {
            if (!mobileVerified && step === 2) {
                document.getElementById('mobile_otp').focus()
            } else if (Object.keys(formik?.errors).length) {
                const error_id = Object.keys(formik.errors)[0]
                document.getElementById(error_id).focus()
            }
        }
    }
    useEffect(() => {
        if (formSubmitted) {
            formik.handleSubmit()
        }
    }, [formSubmitted])

    useEffect(() => {
        setFormSubmitted(false)
    }, [formik.errors])

    /**
     * IW0079
     * This function is called when user clicks on the resend otp button.
     */
    const resendOtp = (otpType) => {
        if (otpType === 1 && !formik.errors.mobile) {
            generateOtp(2, 1)
        }
    }
    /**
     * IW0079
     * This function is called to verify mobile number with otp.
     */
    const verifyOTP = async (otp) => {
        let data = {
            otp_type: '1',
            otp: otp,
            mobile: formik.values.mobile
        }
        const res = await ApiCall("POST", CommonApiEndPoint.verify_user_otp, data)
        if (res.data.status === "success" && res.data.statusCode === 200) {
            setMobileVerified(true)
        } else {
            formik.setFieldValue('mobile_otp', '')
            notify(res.data.message, 'error')
        }
    }
    /**
     * IW0079
     * This effect called when user enter mobile OTP
     */
    useEffect(() => {
        if (formik.values.mobile_otp.length === 6) {
            verifyOTP(formik.values.mobile_otp)
        }
    }, [formik.values.mobile_otp])
    /**
     * IW0079
     * This effect called when mobile verified with respective OTP.
     */
    useEffect(() => {
        if (mobileVerified) {
            setStep(3)
            clearInterval(timer)
        }
    }, [mobileVerified])
    const getInCompleteUserData = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const incomplete_user_id = urlParams.get('incomplete_use_id')
        const res = await axios.get(`${apiEndpoint}${CommonApiEndPoint.get_incomplete_user_data}?incomplete_use_id=${incomplete_user_id}`)
        if (res.data.status === 'success') {
            setIncompleteData([res.data.data])
            formik.setFieldValue('mobile', res.data.data.mobile)
            if (res.data.data.mobile) {
                handleSignUpAndGetOTP()
            }
        } else {
            notify(res.data.message, 'error')
        }
    }
    useEffect(() => {
        if (search && (search?.includes('utm') || search?.includes('medium') || search?.includes('campaign') || search?.includes('source') || search?.includes('content') || search?.includes('term') || search?.includes('query') || search?.includes('action') || search?.includes('p')) && !utmString) {
            const d = new Date()
            d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000))
            const expires = `expires=${d.toUTCString()}`
            document.cookie = `utm_tag=${search}${expires}path=/`
            setUtmString(search)
        }
        if (search?.includes('p=HappyDiwali')) {
            window.open('https://themunim.com/?medium=tmDiwali', '_self')
        }
        if (search?.includes('incomplete_use_id')) {
            getInCompleteUserData()
        }
    }, [search])
    /**
     * IW079
     * This function is called when user enter new affiliate referral code
     */
    const updateAffiliateCount = async (ref_code) => {
        const data = { referral: ref_code }
        await ApiCall("POST", CommonApiEndPoint.update_aff_visit_count, data)
        history.replace(CommonRouter.sign_up)
    }
    /**
     * IW079
     * This effect called when user enter sign up page with affiliate referral code
     */
    useEffect(() => {
        const searchParams = new URLSearchParams(search)
        const ref_code = searchParams.get('referral')
        if (ref_code) {
            const affiliate_referral = localStorage.getItem('_referral')
            if (!affiliate_referral || affiliate_referral !== ref_code) {
                localStorage.setItem('_referral', ref_code)
                updateAffiliateCount(ref_code)
                setReferralId(ref_code)
            } else if (affiliate_referral === ref_code) {
                history.replace(CommonRouter.sign_up)
                setReferralId(ref_code)
            }
        }
    }, [])

    const handleSingUpPopUp = (redirect_url_flag) => {
        handleUserSignUP(formik.values, redirect_url_flag)
    }

    /**
     * IW0077
     * This sign up canocial link set  
     */
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('product')
        setProductName(myParam ? (myParam).toLowerCase() : 'app')
        const link = document.createElement('link')
        link.rel = 'canonical'
        link.href = "https://accounts.themunim.com/sign-up"
        link.id = 'sign-up'
        document.getElementsByTagName('head')[0].appendChild(link) // for IE6
        return () => {
            document.getElementById('sign-up').remove()
        }
    }, [])

    const logAccount = (e) => {
        e.preventDefault();
        const hostname = window.location.hostname
        if (productName === 'gst') {
            if (hostname === 'stageaccounts.themunim.com') {
                window.open('https://stagegst.themunim.com/log-in', '_self')
            } else if (hostname === 'devaccounts.themunim.com') {
                window.open('https://devgst.themunim.com/log-in', '_self')
            } else if (hostname === 'accounts.themunim.com') {
                window.open('https://gst.themunim.com/log-in', '_self')
            } else {
                window.open('http://localhost:4242/log-in', '_self')
            }
        } else if (productName === 'ecom') {
            if (hostname === 'devaccounts.themunim.com') {
                window.open('https://devecom.themunim.com/log-in', '_self')
            } else if (hostname === 'accounts.themunim.com') {
                window.open('https://ecommerce.themunim.com/log-in', '_self')
            } else {
                window.open('http://localhost:5555/log-in', '_self')
            }
        } else {
            if (hostname === 'devaccounts.themunim.com') {
                window.open('https://dev.themunim.com/log-in', '_self')
            } else if (hostname === 'stageaccounts.themunim.com') {
                window.open('https://stage.themunim.com/log-in', '_self')
            } else if (hostname === 'qaaccounts.themunim.com') {
                window.open('https://qa.themunim.com/log-in', '_self')
            } else if (hostname === 'accounts.themunim.com') {
                window.open('https://app.themunim.com/log-in', '_self')
            } else {
                window.open('http://localhost:3000/log-in', '_self')
            }
        }
    }
    return (
        <>
            <Helmet>
                <title>Sign Up Munim & Get started with Free-trial</title>
                <meta property="og:title" content="Sign Up Munim & Get started with Free-trial" />
                <meta name="description" content="Signup for Munim and get the benefits here of online accounting in India. It's a simple online accounting software. Enjoy with easy accounting system." />
            </Helmet>
            <Hotkeys keyName="enter" onKeyDown={onKeyDown} filter={() => true}></Hotkeys>
            {loader ? <FallbackSpinner /> : <Row className='auth-inner munim-login-container p-4'>
                <Col className='d-none d-lg-flex align-items-center justify-content-center p-0 px-sm-4' lg='6' sm='12' >
                    <div className='gst-login-wrapper text-center'>
                        <div className='gst-login-img'>
                            <img src={productName === 'gst' ? gstLogin : appLogin} width={'700px'} />
                        </div>
                    </div>
                </Col>
                <Col className=' d-flex align-items-center justify-content-center munim-bg-white p-0 px-sm-4 gst-resp-color' lg='6' sm='12'>
                    <Col className='gst-responsive-width d-lg-flex align-items-center justify-content-center m-auto' sm='8' lg='10' xl='10'>
                        <div className='gst-login-card-container gst-card p-4 px-5 auth-register-form munim-signin'>
                            <div className='gst-login-logo text-center py-5 pt-4'>
                                <img src={productName === 'gst' ? logoUtility : gstLogo} />
                            </div>
                            <div className='gst-welcome-text'>
                                <h2>Sign Up</h2>
                                <p>{productName === 'app' ? 'All in one solution for Accounting & Billing' : 'All in one solution for GST return filing.'} </p>
                            </div>
                            <div className='munim-switch-btn-wrap'>
                                <div className='munim-switch-btn'>
                                    <Button outline onClick={() => setProductName('app')} className={`${productName === 'gst' ? '' : 'active'}`}>
                                        <span className='fw-bolder'>Accounting & Billing</span>
                                    </Button>
                                    <Button outline onClick={() => setProductName('gst')} className={`${productName === 'gst' ? 'active' : ''}`}>
                                        <span className='fw-bolder'>GST Filing</span>
                                    </Button>
                                </div>
                            </div>
                            <div className="gst-login-form">
                                <Row>
                                    <Col sm='12' lg='12'>
                                        <div className='position-relative mb-3  mid-device-width-col flex-column justify-content-end'>
                                            <label for="Mobile No" className="form-label identix-font-color  required-star  form-label">Mobile No.</label>
                                            <InputGroup className='input-group-merge text-end identix-num-error'>
                                                <InputGroupText className="">
                                                    +91
                                                </InputGroupText>
                                                <InputNumberField value={formik.values.mobile}
                                                    isRequired={true}
                                                    placeholder='99XXXXXX01'
                                                    name='mobile'
                                                    maxLength='10'
                                                    className='ms-0 border-start num-input-bdr'
                                                    handleChange={formik.setFieldValue}
                                                    handleBlur={formik.setFieldTouched}
                                                    autoComplete='off'
                                                    disabled={step !== 1 || incompleteData.mobile}
                                                    errors={formik.errors.mobile}
                                                    touched={formik.touched.mobile}
                                                    onHoverTooltip={true}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    {step !== 1 && <><Col md='12' className={'align-items-end d-flex justify-content-start tab-justify-ends mobile-block mob-mt-1'}>
                                        <div className='d-flex align-items-end justify-content-start identix-mobile-div-otp-dec w-100 identix-sign-in-resend mb-3'>
                                            <div className='position-relative w-100'>
                                                <Label for='email otp' className="required-star"><span> Enter OTP</span></Label>
                                                <InputNumberField value={formik.values.mobile_otp}
                                                    isRequired={true}
                                                    placeholder='OTP'
                                                    className={mobileVerified ? 'otp-verify-icon' : ''}
                                                    name='mobile_otp'
                                                    handleChange={formik.setFieldValue}
                                                    handleBlur={formik.setFieldTouched}
                                                    autoComplete='off'
                                                    maxLength={6}
                                                    disabled={mobileVerified}
                                                    errors={formik.errors.mobile_otp || (!mobileVerified && formik.values.mobile_otp?.length === 6 ? 'Please enter valid OTP!' : '')}
                                                    touched={formik.touched.mobile_otp}
                                                    onHoverTooltip={true}
                                                />
                                            </div>
                                            {!mobileVerified && <div className='ps-2 position-relative identix-send-otp-btn w-100'>
                                                <CustomButton type='button' color='primary' disabled={mobileOtpResendDisable} label='Resend' handleClick={() => resendOtp(1)} />
                                            </div>}

                                        </div>
                                    </Col>
                                        {mobileOtpResendDisable && !mobileVerified && mobileResendOtpRemainingTime !== 60 ? <Col md='6' className='ps-0 mb-1 position-relative w-100'>
                                            Regenerate OTP after {mobileResendOtpRemainingTime} sec
                                        </Col> : ''}
                                        {mobileVerified && <Col sm='12' lg='12'>
                                            <div className='position-relative form-check mb-3'>
                                                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                                    <div className="gst-checkoutbox-filed">
                                                        <InputCheckBoxField value={formik.values.is_login}
                                                            onChange={() => { formik.setFieldValue("password", ""); setShowPassword(!showPassword) }}
                                                            checked={showPassword}
                                                            label='Generate Sign up Password' />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>}
                                    </>}
                                    {step === 1 &&
                                        <div><CustomButton type='button' handleClick={handleSignUpAndGetOTP} disabled={formik.isSubmitting} color='primary' label='Get OTP' block /></div>}
                                    {step !== 1 && <>
                                        {showPassword && <> <Col sm='12' lg='12'>
                                            <div className='position-relative mb-3'>
                                                <InputPasswordField
                                                    label={'Password'}
                                                    placeholder="Password"
                                                    name="password"
                                                    value={formik.values.password}
                                                    maxLength='16'
                                                    isRequired={true}
                                                    handleBlur={formik.setFieldTouched}
                                                    handleChange={formik.setFieldValue}
                                                    errors={formik.errors.password}
                                                    touched={formik.touched.password}
                                                />
                                            </div>
                                        </Col>
                                            <div>
                                                <div className="error-msg-block mb-1 p-1">
                                                    <p>Your password must be at least 6 characters. A recommended password is a combination of uppercase letters, lowercase letters, numbers, and symbols (!$@%).</p>
                                                </div>
                                            </div>

                                        </>}
                                        <div className="text-center mb-3">
                                            <p className='mb-0 munim-fnt-14'>By clicking sign-up button, you agree to our <a href='https://themunim.com/terms-condition/' target='_blank'> {'Terms & Conditions'}</a>,<a href='https://themunim.com/privacy-policy/' target='_blank'> {'Privacy Policy'}</a> & <a href='https://themunim.com/refund-policy/' target='_blank'> {'Refund Policy'}</a>.</p>
                                        </div>
                                        <Col sm='12' lg='12'>
                                            <div className='gst-login-btn'>
                                                <CustomButton type='button' handleClick={handleSignUpAndGetOTP} disabled={formik.isSubmitting} color='primary' label='Sign Up' block loader={getOtpLoader} />
                                            </div>
                                        </Col>
                                    </>}
                                    <Col sm='12' lg='12'>
                                        <div className='gst-register-btn text-center my-3 mb-0 gst-forgot-password'>
                                            <p className='m-0 gst-request-fnt'>
                                                <span className="me-25">Already have an account? </span>
                                                <Link to={detectBrowser() === 'Safari' ? '/' : (window.location.search ? `${CommonRouter.log_in}${history.location.search}` : CommonRouter.log_in)} onClick={detectBrowser() === 'Safari' ? (e) => logAccount(e) : () => { }} className='hover-link'>
                                                    Log In
                                                </Link>
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Col>
            </Row>}
            {
                redirectPopUp && <SignUpRedirectModal popUpActive={redirectPopUp} handleRedirectPopUp={handleSingUpPopUp} popUpTitle={'Where you want to go...'} primaryLabel='Continue' />
            }
        </>
    )
}

export default SignUp