import React, { useEffect, useState } from 'react'
import { Button, Col, InputGroup, InputGroupText, Label, Row } from 'reactstrap'
import gstLogin from '../assets/img/gst-login.svg'
import appLogin from '../assets/img/app-login.svg'
import gstLogo from '../assets/img/Munim-Logo.svg'
import logoUtility from '../assets/img/GST-Logo.svg'
import InputCheckBoxField from '../common_component/custom_field/InputCheckBoxField'
import CustomButton from '../common_component/custom_field/CustomButton'
import InputNumberField from '../common_component/custom_field/InputNumberField'
import InputPasswordField from '../common_component/custom_field/InputPasswordField'
import EmailField from '../common_component/custom_field/EmailField'
import { Link, useParams, useHistory } from 'react-router-dom'
import FallbackSpinner from '../common_component/spinner/FallbackSpinner'
import { Helmet } from 'react-helmet'
import Hotkeys from 'react-hot-keys'
import useNotify from '../custom_hooks/useNotify'
import { ApiCall } from '../helper/axios'
import CommonApiEndPoint from '../helper/commonApiEndPoint'
import CommonRouter from '../helper/commonRoute'
import { useFormik } from 'formik'
import ValidationMessage from '../common_component/Validation'
import emailRegex from '../helper/constants'
import { getCookie, makeRedirectUrl, getUrlDomain, getLoginDomain, getUserIpAddress } from '../helper/commonFunction'
import CommonServerRoute from '../helper/commonServerRoute'
import * as Yup from "yup"

let signInWithOtptimer = ''
function SignIn() {
    const { token, adminToken } = useParams()
    const notify = useNotify()
    const history = useHistory()
    const [preLoading, setPreLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [stepForLoginWithOtp, setStepForLoginWithOtp] = useState(1)
    const [remainigTime, setRemainigTime] = useState(60)
    const [generateOtpError, setGenerateOtpError] = useState(true)
    const [mobileOtpResendDisable, setMobileOtpResendDisable] = useState(false)
    const [signUpProcess, setSignUpProcess] = useState(false)
    const [mobileVerified, setMobileVerified] = useState(false)
    const [finalSchema, setFinalSchema] = useState()
    const [productName, setProductName] = useState('')
    const user_detail = localStorage.getItem('userData')
    const countDown = (time) => {
        let remaining_time = time
        signInWithOtptimer = setInterval(() => {
            remaining_time -= 1
            setRemainigTime(remaining_time)
            if (remaining_time < 1) {
                clearInterval(signInWithOtptimer)
                setMobileOtpResendDisable(false)
            }
        }, 1000)
    }
    /**
     * IW0079
     * This function is called when user click on log-in button
     * also called directly when admin token is available
     */
    const handleUserSignIn = async (value) => {
        setLoader(true)
        const get_domain_enum = await getUrlDomain()
        const signup_data = {
            mobile: value.mobile,
            password: value.password,
            registered_app: get_domain_enum
        }
        const data = {
            username: stepForLoginWithOtp ? value.mobile : value.email,
            password: stepForLoginWithOtp ? '' : value.password,
            is_login: value.is_login,
            otp: stepForLoginWithOtp ? value.otp : '',
            logged_app: get_domain_enum
        }
        if (stepForLoginWithOtp) {
            data.mobile = value.mobile
        }
        if (adminToken) {
            data.admin_token = adminToken
        }
        if (stepForLoginWithOtp) {
            document.cookie = 'is_otp_l = true'
        } else {
            document.cookie = 'is_otp_l = false'
        }
        const ip_address = await getUserIpAddress()
        const header = { ip: ip_address }
        const res = await ApiCall('POST', signUpProcess ? CommonApiEndPoint.sign_up : CommonApiEndPoint.log_in, signUpProcess ? signup_data : data, signUpProcess && header)
        if (res?.data.status === 'success' && res.data.statusCode === 200) {
            const current_time = Date.parse(new Date(new Date()))
            localStorage.setItem('is_log', true)
            localStorage.setItem('common_data', JSON.stringify({ _ett: current_time + 10800000, access_token: res.data.data.access_token, refresh_tokens: res.data.data.refresh_tokens, userData: JSON.stringify({ ...res.data.data, email: value.email }) }))
            const final_redirect_url = makeRedirectUrl(res, new Date(), value, productName)
            if (token) {
                /*THIS IS WORKING ON TOKEN GET IN URL */
                localStorage.setItem('logged_app', final_redirect_url.redirect_url)
                window.open(`${final_redirect_url.create_url}${token && token}`, '_parent')
            } else if (final_redirect_url.redirect_url) {
                if (final_redirect_url.redirect_url?.includes('accept-invitation') || final_redirect_url.redirect_url?.includes('decline-invitation') || final_redirect_url.redirect_url?.includes('/i/')) {
                    const final_url = final_redirect_url.redirect_url.split('/')
                    localStorage.setItem('logged_app', final_url.slice(0, 3).join('/'))
                } else {
                    localStorage.setItem('logged_app', final_redirect_url.redirect_url)
                }
                /*THIS IS WORKING ON GET PARAMETER IN SEARCH URL*/
                window.open(final_redirect_url.create_url, '_parent')
            } else {
                localStorage.setItem('logged_app', `https://${CommonServerRoute.live_app}/`)
                window.open(`https://${CommonServerRoute.live_app}/`, '_parent')
            }
            setPreLoading(false)
            setLoader(false)
        } else {
            formik.setSubmitting(false)
            notify(res?.data.message, 'error')
            setLoader(false)
            setFormSubmitted(false)
            if (!stepForLoginWithOtp) {
                formik.setFieldValue('otp', '')
            }
            formik.setFieldValue('password', '')
        }
    }
    useEffect(() => {
        if (history.location.state?.email) {
            setPreLoading(true)
            handleUserSignIn({ ...history.location.state, is_login: false })
        }
    }, [history.location.state])
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            is_login: false,
            mobile: '',
            otp: '',
            is_checked: false
        },
        validationSchema: finalSchema,
        validateOnChange: true,
        onSubmit: (value) => {
            handleUserSignIn(value)
        }
    })
    const LoginWithMobileSchema = {
        mobile: Yup.string().trim()
            .required(ValidationMessage.is_require)
            .matches(/^[6-9]\d{9}$/gi, ValidationMessage.mn_not_valid)
            .required(ValidationMessage.is_require),
        otp: !formik.values.mobile ? false : Yup.string().trim()
            .required(ValidationMessage.is_require),
        password: signUpProcess && formik.values.is_checked ? Yup.string().trim()
            .min(6, ValidationMessage.signup_valid_password)
            .required(ValidationMessage.is_require) : false
    }
    const LoginWithPasswordSchema = {
        email: isNaN(formik.values.email) ?
            Yup.string().trim()
                .required(ValidationMessage.is_require)
                .email(ValidationMessage.valid_email)
                .matches(emailRegex, ValidationMessage.valid_email) :
            Yup.string().trim()
                .required(ValidationMessage.is_require)
                .matches(/^[6-9]\d{9}$/gi, ValidationMessage.mn_not_valid)
                .required(ValidationMessage.is_require),
        password: Yup.string().trim().required(ValidationMessage.is_require)
    }

    useEffect(() => {
        if (stepForLoginWithOtp) {
            setFinalSchema(Yup.object().shape({ ...LoginWithMobileSchema }))
        } else {
            setFinalSchema(Yup.object().shape({ ...LoginWithPasswordSchema }))
        }
    }, [stepForLoginWithOtp, formik.values.email, formik.values.is_checked])

    const onKeyDown = (keyName, e) => {
        e.preventDefault()
        if (keyName === 'enter' && e.target.textContent !== 'Get OTP') {
            if (!formSubmitted) {
                setFormSubmitted(true)
            }
        } else {
            setGenerateOtpError(false)
            setFormSubmitted(false)
        }
    }
    const handleSignIn = (form_submitted) => {
        if (signUpProcess) {
            if (mobileVerified) {
                const is_form_submitted = form_submitted ? form_submitted : !formSubmitted
                if (is_form_submitted) {
                    setFormSubmitted(true)
                }
            }
        } else {
            const is_form_submitted = form_submitted ? form_submitted : !formSubmitted
            if (is_form_submitted) {
                setFormSubmitted(true)
            }
        }
    }
    useEffect(() => {
        if (generateOtpError && formSubmitted && formik.values.mobile.length === 10) {
            generateOTP()
        } else if (formSubmitted) {
            formik.handleSubmit()
        }

    }, [formSubmitted])
    useEffect(() => {
        setFormSubmitted(false)
    }, [formik.errors]) /**
    * IW0079
    * this effect used when user clear local storage then page refresh and go to log in page it remove cookie ent_dt
    */
    useEffect(() => {
        if (!user_detail) {
            document.cookie = `ent_dt =;expires=${new Date()}`
        }
    }, [user_detail])
    /**
     * IW0079
     * This effect is called when admin want to login from admin side
     * It will send admin token in params and we do direct login in app.
     */
    useEffect(() => {
        if (adminToken && !user_detail) {
            setPreLoading(true)
            handleUserSignIn({ is_login: false, password: '', email: '' })
        }
    }, [adminToken])
    /**
     * IW0079
     * this function is called to set step of login with otp
     * if step is 1, show enter mobile no generate OTP page
     * If step is 2, show OTP verification page. 
     */
    const handleSignInWithOTP = (step) => {
        if (step === 1) {
            formik.setFieldTouched('mobile', false)
            formik.setFieldValue('mobile', formik.values.email)
        }
        if (!step) {
            formik.setFieldValue('otp', '')
            formik.setFieldValue('mobile', '')
            formik.setFieldValue('email', formik.values.mobile)
            setMobileOtpResendDisable(false)
        }
        formik.setFieldValue('password', '')
        setStepForLoginWithOtp(step)
        setGenerateOtpError(true)
        setRemainigTime(0)
        clearInterval(signInWithOtptimer)
    }
    /**
     * IW0079
     * THis function is called when user generate OTP and also called when user regenerate OTP
     */
    const generateOTP = async () => {
        if (formik.values.mobile.length === 10 && !formik.errors.mobile) {
            setMobileOtpResendDisable(true)
            const data = {
                mobile: formik.values.mobile
            }
            const res = await ApiCall("POST", CommonApiEndPoint.generate_login_otp, data, null, 40000)
            if (res.data.status === "success" && res.data.statusCode === 200) {
                if (res.data.data.is_new_register) {
                    setSignUpProcess(true)
                }
                countDown(60)
                if (stepForLoginWithOtp === 1) {
                    setStepForLoginWithOtp(2)
                }
                setFormSubmitted(false)
                setGenerateOtpError(false)
                formik.setFieldTouched('otp', false)
                const url_domain = getLoginDomain()
                if (url_domain === 0) {
                    formik.setFieldValue('otp', res.data.data.mobile_otp)
                    if (res.data.data.is_new_register) {
                        verifyOTP(res.data.data.mobile_otp)
                    } else {
                        handleSignIn(true)
                    }
                }
                notify(res.data.message, 'success')
            } else {
                setFormSubmitted(false)
                setGenerateOtpError(true)
                setMobileOtpResendDisable(false)
                notify(res.data.message, 'error')
            }
        } else if (!formik.touched.mobile || formik.errors.mobile) {
            formik.setFieldTouched('mobile')
            setGenerateOtpError(true)
            setFormSubmitted(false)
            setMobileOtpResendDisable(false)
        }
    }
    const verifyOTP = async (otp) => {
        let data = {
            otp_type: '1',
            otp: otp,
            mobile: formik.values.mobile
        }
        const res = await ApiCall("POST", CommonApiEndPoint.verify_user_otp, data)
        if (res.data.status === "success" && res.data.statusCode === 200) {
            setMobileVerified(true)
        } else {
            formik.setFieldValue('otp', '')
            notify(res.data.message, 'error')
        }
    }
    /**
     * IW0079
     * this function is called when user enter otp 
     * here when otp's length is 6 then do sign in with that otp
     */
    const handleChangeOTP = (name, value) => {
        formik.setFieldValue(name, value)
        if (value.length === 6) {
            if (signUpProcess) {
                verifyOTP(value)
            } else {
                handleSignIn()
            }
        }
    }
    /**
     * IW0079
     * This effect is called first time if user do login with otp, when user come again for login then redirect them in login with otp module.
     */
    useEffect(() => {
        const data = getCookie('is_otp_l')
        if (data === 'true') {
            setStepForLoginWithOtp(1)
        } else if (data) {
            setStepForLoginWithOtp(0)
        }
    }, [])

    /**
     * IW0077
     * This log in canocial link set  
     */
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('product')
        setProductName(myParam ? (myParam).toLowerCase() : 'app')
        const link = document.createElement('link')
        link.rel = 'canonical'
        link.href = "https://accounts.themunim.com/log-in"
        link.id = 'sign-in'
        document.getElementsByTagName('head')[0].appendChild(link) // for IE6
        return () => {
            document.getElementById('sign-in').remove()
        }
    }, [])

    const handleChangeState = () => {
        setSignUpProcess(false)
        setMobileVerified(false)
        setStepForLoginWithOtp(1)
        setFormSubmitted(false)
        setRemainigTime(60)
        setGenerateOtpError(true)
        setMobileOtpResendDisable(false)
        setFinalSchema()
        formik.handleReset()
    }

    return (
        <>
            {preLoading ? <FallbackSpinner /> : <>
                <Helmet>
                    <title>Sign-In Munim: Simplify Your Finances </title>
                    <meta property="og:title" content="Sign-In Munim: Simplify Your Finances" />
                    <meta name="description" content="With Munim's secure login page, you can unlock your productivity. You can easily access your account and manage your financial data. Sign in now." />
                </Helmet>
                <Hotkeys keyName="enter" onKeyDown={onKeyDown} filter={() => true}></Hotkeys>

                <Row className='auth-inner munim-login-container p-4'>
                    <Col className='d-none d-lg-flex align-items-center justify-content-center p-0 px-sm-4' lg='6' sm='12' >
                        <div className='gst-login-wrapper text-center'>
                            <div className='gst-login-img'>
                                <img src={productName === 'gst' ? gstLogin : appLogin} width={'700px'} alt='where you want to go' />
                            </div>
                        </div>
                    </Col>
                    <Col className=' d-flex align-items-center justify-content-center munim-bg-white p-0 px-sm-4 gst-resp-color' lg='6' sm='12'>
                        <Col className='gst-responsive-width d-lg-flex align-items-center justify-content-center m-auto' sm='8' lg='10' xl='10'>
                            <div className='gst-login-card-container gst-card p-4 px-5 munim-signin'>
                                <div className='gst-login-logo text-center py-5 pt-4'>
                                    <img src={productName === 'gst' ? logoUtility : gstLogo} alt='munim logo' />
                                </div>
                                <div className='gst-welcome-text'>
                                    <h2>{signUpProcess ? 'Sign Up' : productName === 'app' ? 'Welcome to Accounting & Billing' : 'Welcome to GST Filing'}</h2>
                                    <p>{productName === 'app' ? 'All in one solution for Accounting & Billing' : 'All in one solution for GST return filing.'} </p>
                                </div>
                                <div className='munim-switch-btn-wrap'>
                                    <div className='munim-switch-btn'>
                                        <Button outline onClick={() => setProductName('app')} className={`${productName === 'gst' ? '' : 'active'}`}>
                                            <span className='fw-bolder'>Accounting & Billing</span>
                                        </Button>
                                        <Button outline onClick={() => setProductName('gst')} className={`${productName === 'gst' ? 'active' : ''}`}>
                                            <span className='fw-bolder'>GST Filing</span>
                                        </Button>
                                    </div>
                                </div>
                                <div className="gst-login-form">
                                    {!stepForLoginWithOtp ? <Row>
                                        <Col sm='12' lg='12'>
                                            <div className='position-relative mb-2'>
                                                <EmailField
                                                    isRequired={true}
                                                    value={formik.values.email}
                                                    label='Username'
                                                    placeholder='Enter Email or Mobile No.'
                                                    name='email'
                                                    handleChange={formik.setFieldValue}
                                                    handleBlur={formik.setFieldTouched}
                                                    autoComplete='off'
                                                    errors={formik.errors.email}
                                                    touched={formik.touched.email}
                                                    maxLength='50'
                                                    autoFocus
                                                />
                                            </div>
                                        </Col>
                                        <Col sm='12' lg='12'>
                                            <div className='position-relative mb-2'>
                                                <InputPasswordField
                                                    label='Password'
                                                    placeholder="Password"
                                                    name="password"
                                                    value={formik.values.password}
                                                    autoComplete="off"
                                                    isRequired={true}
                                                    handleBlur={formik.setFieldTouched}
                                                    handleChange={formik.setFieldValue}
                                                    errors={formik.errors.password}
                                                    touched={formik.touched.password}
                                                />
                                            </div>
                                        </Col>
                                        <Col sm='12' lg='12'>
                                            <div className='position-relative form-check mb-3'>
                                                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                                    <div className="gst-checkoutbox-filed">
                                                        <InputCheckBoxField id='remember-me' value={formik.values.is_login} onChange={() => formik.setFieldValue('is_login', !formik.values.is_login)} checked={formik.values.is_login} label='Trust this device for 30 days' onBlur={() => formik.handleBlur} />
                                                    </div>
                                                    <Link to={window.location.search ? `${CommonRouter.forgot_password}${history.location.search}` : CommonRouter.forgot_password} className='munim-link-color'>
                                                        Forgot password?
                                                    </Link>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm='12' lg='12'>
                                            <div className='gst-login-btn'>
                                                <CustomButton type='button' handleClick={handleSignIn} color='primary' disabled={formik.isSubmitting} label='Log In' block loader={loader} />
                                            </div>
                                            <div className='gst-login-outline-btn py-2 py-sm-3'>
                                                <Button outline className='munim-button-link-none hover-link' onClick={() => handleSignInWithOTP(1)}>
                                                    Log in with OTP
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row> : <Row>
                                        <Col sm='12' lg='12'>
                                            <div className='position-relative mb-2'>
                                                <label for="Mobile No." className="form-label identix-font-color  required-star  form-label">Mobile No.</label>
                                                <InputGroup className='input-group-merge text-end identix-num-error '>
                                                    <InputGroupText className="">
                                                        +91
                                                    </InputGroupText>
                                                    <InputNumberField value={formik.values.mobile}
                                                        isRequired={true}
                                                        tooltipClassName={'identix-mobile-error'}
                                                        placeholder='99XXXXXX01'
                                                        name='mobile'
                                                        maxLength='10'
                                                        className='ms-0 border-start num-input-bdr'
                                                        handleChange={formik.setFieldValue}
                                                        handleBlur={formik.setFieldTouched}
                                                        autoComplete='off'
                                                        disabled={stepForLoginWithOtp === 2}
                                                        errors={formik.errors.mobile}
                                                        touched={formik.touched.mobile}
                                                        onHoverTooltip={true}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        {stepForLoginWithOtp === 2 ? <>
                                            <Col sm='12' lg='12' className={!remainigTime ? 'align-items-end d-flex justify-content-start tab-justify-ends mobile-block mob-mt-1' : ''}>
                                                <div className='d-flex align-items-end justify-content-start identix-mobile-div-otp-dec w-100 identix-sign-in-resend gap-2'>
                                                    <div className='mb-1 pe-0 position-relative w-100'>
                                                        <Label className="form-label required-star" for="Enter OTP">
                                                            Enter OTP
                                                        </Label>
                                                        <InputNumberField value={formik.values.otp}
                                                            placeholder='OTP'
                                                            className={mobileVerified ? 'otp-verify-icon' : ''}
                                                            name='otp'
                                                            handleChange={handleChangeOTP}
                                                            handleBlur={formik.setFieldTouched}
                                                            autoComplete='off'
                                                            maxLength={6}
                                                            errors={formik.errors.otp}
                                                            touched={formik.touched.otp}
                                                            onHoverTooltip={true}
                                                            disabled={mobileVerified}
                                                        />
                                                    </div>
                                                    {!mobileVerified && <div className='mb-1 position-relative identix-send-otp-btn'>
                                                        <CustomButton type='button' color='primary' handleClick={() => generateOTP()} disabled={mobileOtpResendDisable} label='Resend' />
                                                    </div>}
                                                </div>
                                            </Col>
                                            {remainigTime && !mobileVerified ? <Col md='12' className='position-relative mb-1'>
                                                You can request another OTP in {remainigTime} sec
                                            </Col> : ''}
                                        </> : ''}
                                        {stepForLoginWithOtp && !signUpProcess ? <Col><div className='form-check mb-3'>
                                            <InputCheckBoxField id='remember-me' value={formik.values.is_login} onChange={() => formik.setFieldValue('is_login', !formik.values.is_login)} checked={formik.values.is_login} onBlur={formik.handleBlur} label='Trust this device for 30 days' />
                                        </div></Col> : ''}
                                        {mobileVerified && <Col sm='12' lg='12'>
                                            <div className='position-relative form-check mb-3'>
                                                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                                    <div className="gst-checkoutbox-filed">
                                                        <InputCheckBoxField value={formik.values.is_checked}
                                                            onChange={() => formik.setFieldValue('is_checked', !formik.values.is_checked)}
                                                            checked={formik.values.is_checked}
                                                            label='Generate Sign up Password' />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>}
                                        {formik.values.is_checked && <> <Col sm='12' lg='12'>
                                            <div className='position-relative mb-3'>
                                                <InputPasswordField
                                                    label={'Password'}
                                                    placeholder="Password"
                                                    name="password"
                                                    value={formik.values.password}
                                                    maxLength='16'
                                                    isRequired={true}
                                                    handleBlur={formik.setFieldTouched}
                                                    handleChange={formik.setFieldValue}
                                                    errors={formik.errors.password}
                                                    touched={formik.touched.password}
                                                />
                                            </div>
                                        </Col>
                                            <div>
                                                <div className="error-msg-block mb-1 p-1">
                                                    <p>Your password must be at least 6 characters. A recommended password is a combination of uppercase letters, lowercase letters, numbers, and symbols (!$@%).</p>
                                                </div>
                                            </div>
                                        </>}
                                        {mobileVerified && <div className="text-center mb-1">
                                            <p>By clicking sign-up button, you agree to our <a href='https://themunim.com/terms-condition/' target='_blank'> {'Terms & Conditions'}</a>,<a href='https://themunim.com/privacy-policy/' target='_blank'> {'Privacy Policy'}</a> & <a href='https://themunim.com/refund-policy/' target='_blank'> {'Refund Policy'}</a>.</p>
                                        </div>}
                                        {stepForLoginWithOtp === 2 ? <div>
                                            <CustomButton type='button' handleClick={handleSignIn} color='primary' disabled={formik.isSubmitting} label={signUpProcess ? 'Sign Up' : 'Log In'} block loader={loader} />
                                        </div> : ''}
                                        {stepForLoginWithOtp === 1 ? <>
                                            <div className=''>
                                                <CustomButton type='button' handleClick={() => generateOTP()} color='primary' disabled={mobileOtpResendDisable} label='Get OTP' block />
                                            </div>
                                        </> : ''}
                                        {!signUpProcess && <div><p className='gst-login-outline-btn py-3 m-0'>
                                            <Button outline className='munim-button-link-none hover-link' onClick={(e) => { e.preventDefault(); handleSignInWithOTP(0) }} >
                                                Log In with password
                                            </Button>
                                        </p></div>}
                                    </Row>}
                                    {!signUpProcess && <Col sm='12' lg='12'>
                                        <div className='gst-register-btn text-center mt-3 munim-sm-mt-12'>
                                            <CustomButton type='button' handleClick={() => (window.location.search ? history.push(`/sign-up${window.location.search}`) : history.push(CommonRouter.sign_up))} color='success' label='Register Account' />
                                        </div>
                                    </Col>}
                                    {signUpProcess && <>
                                        <div className='border-bottom mt-4'></div>
                                        <Col sm='12' lg='12'>
                                            <div className='gst-register-btn text-center my-3 mb-0 gst-forgot-password'>
                                                <p className='m-0 gst-request-fnt'>
                                                    <span className="me-25">Already have an account? </span>
                                                    <Link onClick={() => handleChangeState()} className='hover-link'>
                                                        Log In
                                                    </Link>
                                                </p>
                                            </div>
                                        </Col>
                                    </>}
                                </div>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </>}
        </>
    )
}

export default SignIn