import axios from 'axios'
import CommonRouter from './commonRoute'
const { apiEndpoint } = require('./commonApi')
const service = axios.create({
  headers: {

  }
})
const handleSuccess = (response) => {
  return response
}

const handleError = (error) => {
  return Promise.reject(error)
}
service.interceptors.response.use(handleSuccess, handleError)

/**
 * IW0079
 * When someone changes in local storage detail at that time that person account will logout for security purpose
 */
const renewToken = () => {
  if (!window.location.pathname.includes('/s/') || !window.location.pathname.includes('/p/')) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_tokens')
    localStorage.removeItem('userData')
    window.location.href = CommonRouter.log_in
  }
}
export const ApiCall = async (method, path, payload, header, timeout = 10000) => {
  if (header && !header['access-token'] && (!path.includes('sign-up') && !path.includes('generate-user-otp') && !path.includes('forgot-password') && !path.includes('update-aff-visit-count'))) {
    renewToken()
  } else {
    try {
      const responce = await service.request({
        method,
        url: apiEndpoint + path,
        responseType: 'json',
        data: payload,
        timeout,
        headers: header
      })
      return responce
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(`${error}, Server is not responding, please try again after some time`)
      }
      if (error.response?.data?.statusCode === 401 && header && !header['access-token']) {
        if (error.response.data.access_expire) {
          renewToken()
        } else if (error.response.data.refresh_expire) {
          return error.response
        }
      } else {
        return error.response
      }
    }
  }
}
/**
 * IW0079
 * here flag is true when api call occur and user is not login
 */
export const GetApiCall = async (method, path, header, flag = false) => {
  if (!header['access-token'] && !flag) {
    renewToken()
  } else {
    try {
      const responce = await service.request({
        method,
        url: apiEndpoint + path,
        responseType: 'json',
        headers: header

      })
      return responce
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(`${error}, Server is not responding, please try again after some time`)
      }
      if (error.response?.data?.statusCode === 401) {
        if (error.response.data.access_expire) {
          renewToken()
        } else {
          return error.response
        }
      } else {
        return error.response
      }
    }
  }
}