const CommonApiEndPoint = {
    user_details: '/user-details',
    renew_token: '/renew-token',
    forgot_password: '/forgot-password',
    reset_password: '/reset-password',
    log_in: '/log-in',
    sign_up: '/sign-up',
    generate_login_otp: '/generate-login-otp',
    generate_user_otp: '/generate-user-otp',
    verify_user_otp: '/verify-user-otp',
    update_aff_visit_count: '/update-aff-visit-count',
    get_incomplete_user_data: '/get-incomplete-user-data',
}
export default CommonApiEndPoint
