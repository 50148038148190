const { default: CommonRouter } = require("./commonRoute")
const { default: CommonServerRoute } = require("./commonServerRoute")
try {
    let apiEndpoint = ''
    let commonApi = ''
    let sampleFileApi = ''
    let socketApi = ''
    let chatApi = ''
    let product = {
        app: '',
        gst: '',
        ecom: ''
    }
    const hostname = window.location.hostname
    if (hostname === 'localhost') {
        apiEndpoint = 'http://localhost:8070/api'
        commonApi = 'http://localhost:8070/'
        product = {
            app: 'http://localhost:3000',
            gst: 'http://localhost:4242',
            ecom: 'http://localhost:5555'
        }
    } else if (hostname === CommonServerRoute.dev_account) {
        apiEndpoint = 'https://devapi.themunim.com/api'
        commonApi = 'https://devapi.themunim.com/'
        socketApi = 'https://devapi.themunim.com/'
        product = {
            app: 'https://dev.themunim.com',
            gst: 'https://devgst.themunim.com',
            ecom: 'https://devecom.themunim.com'
        }
    } else if (hostname === CommonServerRoute.stage_account) {
        apiEndpoint = 'https://stageapi.themunim.com/api'
        commonApi = 'https://stageapi.themunim.com/'
        socketApi = 'https://stageapi.themunim.com/'
        product = {
            app: 'https://stage.themunim.com',
            gst: 'https://stagegst.themunim.com'
        }
    } else if (hostname === CommonServerRoute.qa_account) {
        apiEndpoint = 'https://qaapi.themunim.com/api'
        commonApi = 'https://qaapi.themunim.com/'
        socketApi = 'https://qaapi.themunim.com/'
        product = {
            app: 'https://qa.themunim.com',
            gst: 'https://devgst.themunim.com'
        }
    } else if (hostname === CommonServerRoute.live_account) {
        apiEndpoint = 'https://api.themunim.com/api'
        commonApi = 'https://api.themunim.com/'
        socketApi = 'https://api.themunim.com/'
        product = {
            app: 'https://app.themunim.com',
            gst: 'https://gst.themunim.com',
            ecom: 'https://ecommerce.themunim.com'
        }
    } else if (!myParam) {
        if (window.location.host == CommonServerRoute.live_account) {
            apiEndpoint = 'https://api.themunim.com/api'
            commonApi = 'https://api.themunim.com/'
            socketApi = 'https://api.themunim.com/'
            product = {
                app: 'https://app.themunim.com',
                gst: 'https://gst.themunim.com'
            }
        } else {
            apiEndpoint = 'https://stageapi.themunim.com/api'
            commonApi = 'https://stageapi.themunim.com/'
            socketApi = 'https://stageapi.themunim.com/'
            product = {
                app: 'https://stage.themunim.com',
                gst: 'https://stagegst.themunim.com'
            }
        }
    }
    module.exports = {
        apiEndpoint,
        commonApi,
        sampleFileApi,
        socketApi,
        chatApi,
        product
    }
} catch (error) {
    window.location.href = CommonRouter.log_in
}