import React, { useEffect, useState } from "react"
import CommonRouter from "./helper/commonRoute"
import Register from "./register"
import { BrowserRouter as AppRouter, Route, Switch, Redirect } from 'react-router-dom'
import FallbackSpinner from "./common_component/spinner/FallbackSpinner"
const createHost = require('cross-domain-storage/host')
import './assets/css/style.css'
import CommonServerRoute from "./helper/commonServerRoute"
import SupportChat from "./common_component/SupportChat"
import { product } from "./helper/commonApi"
import { makeRedirectUrl } from "./helper/commonFunction"
import useHotjar from "./custom_hooks/useHotjar"

function App() {
  const [preLoading, setPreLoading] = useState(false)

  useEffect(async () => {
    setPreLoading(true)
    createHost([
      {
        /*This host use in  local munim */
        origin: 'http://localhost:3000',
        allowedMethods: ['get', 'set', 'remove']
      }, {
        /*This host use in local gst */
        origin: 'http://localhost:4242',
        allowedMethods: ['get', 'set', 'remove']
      }, {
        /*This host use in local gst */
        origin: 'http://localhost:5555',
        allowedMethods: ['get', 'set', 'remove']
      }, {
        /*This host use in dev munim */
        origin: `https://${CommonServerRoute.dev_app}`,
        allowedMethods: ['get', 'set', 'remove']
      }, {
        /*This host use in dev gst */
        origin: `https://${CommonServerRoute.dev_gst}`,
        allowedMethods: ['get', 'set', 'remove']
      }, {
        /*This host use in dev gst */
        origin: `https://${CommonServerRoute.dev_ecom}`,
        allowedMethods: ['get', 'set', 'remove']
      }, {
        /*This host use in stage munim */
        origin: `https://${CommonServerRoute.stage_app}`,
        allowedMethods: ['get', 'set', 'remove']
      }, {
        /*This host use in stage munim */
        origin: `https://${CommonServerRoute.stage_gst}`,
        allowedMethods: ['get', 'set', 'remove']
      }
      , {
        /*This host use in live munim */
        origin: `https://${CommonServerRoute.live_app}`,
        allowedMethods: ['get', 'set', 'remove']
      }, {
        /*This host use in live gst */
        origin: `https://${CommonServerRoute.live_gst}`,
        allowedMethods: ['get', 'set', 'remove']
      }, {
        /*This host use in live ecommerce */
        origin: `https://${CommonServerRoute.live_ecom}`,
        allowedMethods: ['get', 'set', 'remove']
      },
      {
        /*This host use in qa app */
        origin: `https://${CommonServerRoute.qa_app}`,
        allowedMethods: ['get', 'set', 'remove']
      }
    ])
    const get_redirect_url = window.location.search
    if (!get_redirect_url || (get_redirect_url && !get_redirect_url.match('is_logout') && !get_redirect_url.match('is_logged_app') && !get_redirect_url.match('is_check_storage'))) {
      if (localStorage.getItem('is_log') && localStorage.getItem('common_data')) {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('product')
        /**
         * iw0090
         * for solution of safari hortcut and crome
          let myDynamicManifest =
         {
           "short_name": "The Munim",
           "name": "The Munim",
           "icons": [
             {
               "src": "favicon.ico",
               "sizes": "64x64 32x32 24x24 16x16",
               "type": "image/x-icon"
             }
           ],
           "start_url":`${window.location.hostname}/log-in?redirect_url=${myParam}`,
           "display": "standalone",
           "theme_color": "#000000",
           "background_color": "#ffffff"
         }
         const stringManifest = JSON.stringify(myDynamicManifest);
         const blob = new Blob([stringManifest], {type: 'application/json'});
         const manifestURL = URL.createObjectURL(blob);
         document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
 
         var link = document.createElement('Link');
         link.rel = "manifest";
         link.setAttribute('href', 'data:application/json;charset=8' + stringManifest) 
         */
        const decode_data = myParam ? myParam : ''
        if (decode_data) {
          window.open(product[decode_data], '_parent')
        } else {
          window.open(`${localStorage.getItem('logged_app')}`, '_parent')
        }
      } else {
        setPreLoading(false)
      }
    } else if (get_redirect_url.match('is_logged_app')) {
      const query_search = window.location.search.split('&product=')
      const final_redirect_url = makeRedirectUrl({}, new Date(), {}, query_search[1])
      localStorage.setItem('logged_app', final_redirect_url?.redirect_url)
      setPreLoading(false)
    } else if (get_redirect_url.match('is_logout')) {
      localStorage.removeItem('is_log')
      localStorage.removeItem('logged_app')
      localStorage.removeItem('common_data')
      const query_search = window.location.search.split('&')
      window.open(window.location.origin + window.location.pathname + '?' + query_search[1], '_parent')
      setPreLoading(false)
    } else {
      setPreLoading(false)
    }
  }, [])

  let callHotJar
  if (window.location.hostname === CommonServerRoute.live_account) {
    callHotJar = useHotjar()
  }

  useEffect(() => {
    if (window.location.hostname === CommonServerRoute.live_account) {
      callHotJar()
    }
  }, [])


  return (
    <>
      <AppRouter>
        {preLoading ? <FallbackSpinner /> : <Switch>
          <Route exact path={CommonRouter.log_in} component={Register} />
          <Route exact path={`${CommonRouter.log_in}/:token`} component={Register} />
          <Route exact path={CommonRouter.sign_in} component={Register} />
          <Route exact path={`${CommonRouter.sign_in}/:token`} component={Register} />
          <Route exact path={CommonRouter.sign_up} component={Register} />
          <Route exact path={`${CommonRouter.sign_up}/:token`} component={Register} />
          <Route exact path={CommonRouter.forgot_password} component={Register} />
          <Route exact path={`${CommonRouter.forgot_password}/:token`} component={Register} />
          <Route exact path={CommonRouter.reset_password} component={Register} />
          <Route exact path={`${CommonRouter.reset_password}/:token`} component={Register} />
          <Route exact path={`${CommonRouter.active_email}/:token`} component={Register} />
          <Route exact path={`${CommonRouter.verify_email_preference}/:token`} component={Register} />
          <Route exact path={`${CommonRouter.admin_login}/:adminToken`} component={Register} />
          <Route exact path='/' render={() => { return <Redirect to={CommonRouter.log_in} /> }} />
          <Route path='*' render={() => { return <Redirect to={CommonRouter.log_in} /> }} />
        </Switch>}
      </AppRouter>
      {window.location.hostname === 'accounts.themunim.com' && !window.location.href.includes('is_check_storage') ? <SupportChat firstLoad={preLoading} /> : ''}
    </>
  )
}

export default App
