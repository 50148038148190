import React from 'react'
// ** Reactstrap Imports
import { Input, Label } from 'reactstrap'
import { useHistory } from 'react-router-dom'

const EmailField = ({ value, placeholder, name, handleChange, handleBlur, label, autoFocus, disabled, errors, touched, isRequired, className, maxLength, handleOnKeyUp }) => {
    const history = useHistory()
    return (
        <>
            {label ? <Label className={`form-label identix-font-color ${isRequired === true && !disabled ? 'required-star' : ''}`} for={label}>
                {label}
            </Label> : ''}
            <Input
                value={value}
                placeholder={disabled && history.location.pathname.includes('view') ? '-' : placeholder}
                name={name}
                className={className}
                onKeyUp={handleOnKeyUp}
                onChange={!disabled ? (e) => handleChange(name, (e.target.value).toLowerCase()) : () => { }}
                onBlur={() => handleBlur(name)}
                autoFocus={autoFocus}
                autoComplete='true'
                type='email'
                id={name}
                onInput={(e) => { e.target.value = (e.target.value).toLowerCase() }}
                disabled={disabled}
                invalid={errors && touched && true}
                maxLength={maxLength}
            />
            <div className='error-hover-tooltip'></div>
            {errors && touched && (
                <div className='custom-tooltip'>
                    <div className='custom-tooltip-label'>
                        <p> {errors}</p>
                    </div>
                </div>
            )}
        </>
    )
}

export default EmailField
