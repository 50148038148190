import React from 'react'
import { getUserIpAddress } from '../helper/commonFunction'
function useHotjar() {
    const ExecuteHotjar = async () => {
        const ip_address = await getUserIpAddress()
        const detail = {
            'Ip address': ip_address ? ip_address : ''
        }
        window.hj('identify', null, { ...detail })
    }
    return ExecuteHotjar
}

export default useHotjar
