import React, { useState } from 'react'
import { Eye, EyeOff } from 'react-feather'
// ** Reactstrap Imports
import { FormFeedback, Input, InputGroup, InputGroupText, Label } from 'reactstrap'
const InputPasswordField = ({ value, placeholder, name, handleChange, label, handleBlur, disabled, errors, touched, tooltipText, isRequired, autoFocus }) => {
    const [showPassword, setShowPassword] = useState(false)
    return (
        <>
            {label ? <Label className={` ${tooltipText ? 'd-flex align-items-center' : ''} ${isRequired ? 'required-star' : ''}`} for={label}>
                {label}
            </Label> : ''}
            <InputGroup className='input-group-merge text-end identix-num-error'>
                <Input
                    value={value}
                    placeholder={placeholder}
                    name={name}
                    id={name}
                    onChange={!disabled ? (e) => handleChange(name, e.target.value) : () => { }}
                    onBlur={() => handleBlur(name)}
                    autoComplete='true'
                    type={showPassword ? 'text' : 'password'}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    invalid={errors && touched && true}
                />
                <div className='error-hover-tooltip'></div>
                {errors && touched && (
                    <div className='custom-tooltip'>
                        <div className='custom-tooltip-label'>
                            <p> {errors}</p>
                        </div>
                    </div>
                )}
                <InputGroupText className="px-3" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyeOff size={14} /> : <Eye size={14} />}
                </InputGroupText>
            </InputGroup>

        </>
    )
}

export default InputPasswordField
