import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import ForgotPassword from "./ForgotPassword"
import ResetPassword from "./ResetPassword"
import SignIn from "./SignIn"
import CommonRouter from "../helper/commonRoute"
import SignUp from "./SignUp"
const Register = () => {
    const history = useHistory()
    const navigatorOnlineStatus = typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true
    const [onlineStatus, setOnlineStatus] = useState(navigatorOnlineStatus)
    const [isShowStatus, setIsShowStatus] = useState(false)
    /**
    * IW0110
    * This function used when network interrupt
    */
    const goOnline = () => setOnlineStatus(true)
    const goOffline = () => setOnlineStatus(false)
    useEffect(() => {
        window.addEventListener('online', goOnline)
        window.addEventListener('offline', goOffline)
        return () => {
            window.removeEventListener('online', goOnline)
            window.removeEventListener('offline', goOffline)
        }
    }, [])
    /**
    * IW0110
    * This effect call on hide & show toast message for network interrupt
    */
    useEffect(() => {
        if (!onlineStatus) {
            setIsShowStatus(true)
        } else if (onlineStatus && isShowStatus) {
            setTimeout(() => {
                setIsShowStatus(false)
                window.location.reload()
            }, 1500)
        }
    }, [onlineStatus])
    return (
        <>
            <div className="auth-wrapper">
                {history.location.pathname.includes(CommonRouter.log_in) || history.location.pathname.includes('admin-login') && !localStorage.getItem('is_log') ? <SignIn /> : history.location.pathname.includes(CommonRouter.sign_up) && !localStorage.getItem('is_log') ? <SignUp /> : history.location.pathname.includes(CommonRouter.forgot_password) ? <ForgotPassword /> : history.location.pathname.includes(CommonRouter.reset_password) ? <ResetPassword /> : ''}
                <span className={`internet-status ${isShowStatus ? onlineStatus ? 'internet-back-online' : 'internet-connection' : ''}`}>{isShowStatus ? onlineStatus ? 'Back online' : 'No internet connection' : ''}</span>
            </div>
        </>
    )
}

export default Register