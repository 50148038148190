import React from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import CustomButton from '../custom_field/CustomButton'
import logoUtility from '../../assets/img/popup-logo.svg'
import logoManage from '../../assets/img/logo-manage.svg'
export default function SignUpRedirectModal({ popUpTitle, popUpActive, handleRedirectPopUp, primaryLabel }) {
    return (
        <Modal wrapClassName='identix-discard-modal identix-register-popup' autoFocus={false} isOpen={popUpActive} className='modal-dialog-centered'>
            <ModalHeader className='identix-register-popup-header'>{popUpTitle}</ModalHeader>
            <ModalBody>
                <div className='cl-popup-container d-flex'>
                    <div className='cl-popup-column'>
                        <div className='cl-popup-header text-center'>
                            <img src={logoUtility} className='popup-munim-logo-gst' />
                        </div>
                        <div className='cl-popup-body'>
                            <div className='cl-popup-utility text-center'>
                                <div className='cl-popup-listing'>
                                    <ul>
                                        <li>GSTR 1</li>
                                        <li>GSTR 3B</li>
                                        <li>Nil Retuns</li>
                                        <li>Mostly use by: Charted Accountant</li>
                                        <li>Robotic Return filing Desktop App</li>
                                        <li className='d-inline-block cl-popup-btn'><CustomButton type='button' color='primary' handleClick={() => handleRedirectPopUp(2)} label={primaryLabel} mobileBtnNone='w-auto' /></li>
                                    </ul >
                                </div >
                            </div >
                        </div >
                    </div >
                    <div className='cl-popup-column'>
                        <div className='cl-popup-header text-center'>
                            <img src={logoManage} className='popup-munim-logo-acc' />
                        </div>
                        <div className='cl-popup-body'>
                            <div className='cl-popup-acc text-center'>
                                <div className='cl-popup-listing'>
                                    <ul>
                                        <li>Inventory Managment</li>
                                        <li>E-Invoicing Software</li>
                                        <li>GST Reconciliation </li>
                                        <li>E-way Bill Software</li>
                                        <li>Bookkeeping Software</li>
                                        <li className='d-inline-block cl-popup-btn'> <CustomButton outline={false} type='button' color='primary' handleClick={() => handleRedirectPopUp(1)} mobileBtnNone='w-auto' autoFocus label={primaryLabel} /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </ModalBody >


        </Modal >
    )
}
