import React, { useEffect, useState } from 'react'
import { Button, Col, InputGroup, InputGroupText, Label, Row } from 'reactstrap'
import gstLogin from '../assets/img/gstlogin.svg'
import gstLogo from '../assets/img/logo.svg'
import CustomButton from '../common_component/custom_field/CustomButton'
import Hotkeys from 'react-hot-keys'
import { Helmet } from 'react-helmet'
import useNotify from '../custom_hooks/useNotify'
import { useHistory } from 'react-router-dom'
import { ApiCall } from '../helper/axios'
import { useFormik } from 'formik'
import * as Yup from "yup"
import ValidationMessage from '../common_component/Validation'
import CommonApiEndPoint from '../helper/commonApiEndPoint'
import CommonRouter from '../helper/commonRoute'
import InputNumberField from '../common_component/custom_field/InputNumberField'
import { getUserIpAddress, getLoginDomain } from '../helper/commonFunction'
import emailRegex from '../helper/constants'
import EmailField from '../common_component/custom_field/EmailField'

function ForgotPassword() {
    const notify = useNotify()
    const history = useHistory()
    const [getOtpLoader, setGetOtpLoader] = useState(false)
    const [step, setStep] = useState(1)
    const [mobileOtpResendDisable, setMobileOtpResendDisable] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [mobileVerified, setMobileVerified] = useState(false)
    const [mobileResendOtpRemainingTime, setMobileOtpRemainingTime] = useState(60)
    const [finalSchema, setFinalSchema] = useState()
    const [verifyToken, setVerifyToken] = useState("");
    let timer
    let mobileTimer

    const countDown = (time) => {
        let remaining_time = time
        let final_timer = timer;
        final_timer = setInterval(() => {
            remaining_time -= 1
            setMobileOtpRemainingTime(remaining_time)
            if (remaining_time < 1) {
                clearInterval(final_timer)
            }
        }, 1000)
    }

    const generateOtp = async (action, otpType) => {
        if (action === 2) {
            if (otpType === 1) {
                setMobileOtpResendDisable(true)
            }
        }
        const ip_address = await getUserIpAddress()
        const data = {
            email: isNaN(formik.values.user_name) ? formik.values.user_name : '',
            mobile: !isNaN(formik.values.user_name) ? formik.values.user_name : '',
            action
        }
        if (action === 2) {
            data.otp_type = otpType
        }
        const header = { ip: ip_address }
        if ((step === 1 && !otpType) || (step === 2 && otpType)) {
            const res = await ApiCall("POST", CommonApiEndPoint.forgot_password, data, header, 40000)
            if (res.data.status === "success" && res.data.statusCode === 200) {
                setStep(2)
                const url_domain = getLoginDomain()
                setGetOtpLoader(false)
                if (url_domain !== 0) {
                    if (action === 1 || otpType === 1) {
                        countDown(60)
                        setTimeout(() => {
                            setMobileOtpResendDisable(false)
                            setMobileOtpRemainingTime(60)
                        }, 60000)
                        formik.setFieldTouched('mobile_otp', false)
                    }
                } else {
                    formik.setFieldValue('mobile_otp', res.data.data.mobile_otp)
                    setMobileVerified(true)
                    verifyOTP(res.data.data.mobile_otp)
                }
                notify(res.data.message, 'success')
                setFormSubmitted(false)
                formik.setSubmitting(false)
            } else {
                setMobileOtpResendDisable(false)
                formik.setSubmitting(false)
                setGetOtpLoader(false)
                setFormSubmitted(false)
                notify(res.data.message, 'error')
            }
        }
    }

    const handleForgotPassword = async (value) => {
        setGetOtpLoader(true)
        if (step === 1) {
            setMobileOtpResendDisable(true)
            generateOtp(1)
        } else if (step === 3) {
            formik.setSubmitting(false)
            setFormSubmitted(false)
            history.push(`${CommonRouter.reset_password}${history.location.search}`, { verifyToken: verifyToken })
        }
    }

    const formik = useFormik({
        initialValues: {
            user_name: "",
            mobile_otp: ""
        },
        validationSchema: finalSchema,
        onSubmit: (value) => {
            handleForgotPassword(value)
        }
    })
    const firstStepSchema = {
        user_name: isNaN(formik.values.user_name) ?
            Yup.string().trim()
                .required(ValidationMessage.is_require)
                .email(ValidationMessage.valid_email)
                .matches(emailRegex, ValidationMessage.valid_email) :
            Yup.string().trim()
                .required(ValidationMessage.is_require)
                .matches(/^[6-9]\d{9}$/gi, ValidationMessage.mn_not_valid)
                .required(ValidationMessage.is_require),
    }

    const secondStepSchema = {
        mobile_otp: Yup.string().trim()
            .required(ValidationMessage.otp_req)
            .min(6, ValidationMessage.otp_min_max)
            .max(6, ValidationMessage.otp_min_max),
    }

    useEffect(() => {
        if (step === 1) {
            setFinalSchema(Yup.object().shape({ ...firstStepSchema }))
        } else if (step === 2 || step === 3) {
            setFinalSchema(Yup.object().shape({ ...firstStepSchema, ...secondStepSchema }))
        }
    }, [step, formik.values.user_name])

    const onKeyDown = (keyName, e) => {
        e.preventDefault()
        if (formik.dirty && e.keyCode === 13) {
            formik.handleSubmit()
        }
    }

    const handleGetOTP = () => {
        if (!formSubmitted) {
            setFormSubmitted(true)
        } else {
            if (!mobileVerified && step === 2) {
                document.getElementById('mobile_otp').focus()
            }
        }
    }

    useEffect(() => {
        if (formSubmitted) {
            formik.handleSubmit()
        }
    }, [formSubmitted])

    useEffect(() => {
        setFormSubmitted(false)
    }, [formik.errors])

    const resendOtp = (otpType) => {
        if (otpType === 1 && !formik.errors.user_name) {
            generateOtp(2, 1)
        }
    }

    const verifyOTP = async (otp) => {
        let data = {
            otp_type: !isNaN(formik.values.user_name) ? '1' : '2',
            otp: otp,
            email: isNaN(formik.values.user_name) ? formik.values.user_name : '',
            mobile: !isNaN(formik.values.user_name) ? formik.values.user_name : '',
            is_reset_password: 1,
        }
        const res = await ApiCall("POST", CommonApiEndPoint.verify_user_otp, data)
        if (res.data.status === "success" && res.data.statusCode === 200) {
            setMobileVerified(true)
            setVerifyToken(res.data.data.resetToken)
            history.push(`${CommonRouter.reset_password}${history.location.search}`, { verifyToken: res.data.data.resetToken })
        }
    }

    useEffect(() => {
        if (formik.values.mobile_otp.length === 6) {
            verifyOTP(formik.values.mobile_otp)
        }
    }, [formik.values.mobile_otp])

    useEffect(() => {
        if (mobileVerified) {
            setStep(3)
            clearInterval(timer)
        }
    }, [mobileVerified])

    return (
        <>
            <Helmet>
                <title>Forgot Password - Munim</title>
                <meta property="og:title" content="Forgot Password - Munim" />
                <meta name="description" content="With Munim's forget password page, you can easily reset your password. Returning to access to your account is simple and safe. Protect your financial data with us." />
            </Helmet>
            <Hotkeys keyName="enter" onKeyDown={onKeyDown} filter={() => true}></Hotkeys>
            <Row className='auth-inner m-0'>
                <Col className='d-none d-lg-flex align-items-center justify-content-center p-5' lg='5' sm='12' >
                    <div className='gst-login-wrapper text-center'>
                        <div className='gst-login-img gst-forget-img'>
                            <img src={gstLogin} width={'700px'} />
                        </div>
                    </div>
                </Col>
                <Col className='  d-flex align-items-center justify-content-center munim-bg-white p-4 gst-resp-color' lg='7' sm='12'>
                    <Col className='gst-responsive-width d-lg-flex align-items-center justify-content-center m-auto' sm='8' lg='10' xl='7'>
                        <div className='gst-login-card-container gst-card p-4 px-5'>
                            <div className='gst-login-logo text-center py-5 pt-4'>
                                <img src={gstLogo} />
                            </div>
                            <div className='gst-welcome-text'>
                                <h2>Forgot Password</h2>
                                <p>Please enter your registered mobile no. or email here!</p>
                            </div>
                            <div className="gst-login-form">
                                <Row>
                                    <Col sm='12' lg='12'>
                                        <div className='position-relative mb-3 mid-device-width-col flex-column justify-content-end'>
                                            <Col sm='12' lg='12'>
                                                <div className='position-relative mb-2 '>
                                                    <EmailField
                                                        isRequired={true}
                                                        value={formik.values.user_name}
                                                        label='Username'
                                                        placeholder='Enter Email or Mobile No.'
                                                        name='user_name'
                                                        handleChange={formik.setFieldValue}
                                                        handleBlur={formik.setFieldTouched}
                                                        autoComplete='off'
                                                        disabled={step === 2}
                                                        errors={formik.errors.user_name}
                                                        touched={formik.touched.user_name}
                                                        maxLength='50'
                                                        autoFocus
                                                    />
                                                </div>
                                            </Col>
                                        </div>
                                    </Col>
                                    {step !== 1 && <><Col md='12' className={'align-items-end d-flex justify-content-start tab-justify-ends mobile-block mob-mt-1'}>
                                        <div className='d-flex align-items-end justify-content-start identix-mobile-div-otp-dec w-100 identix-sign-in-resend'>
                                            <div className='pe-2 position-relative w-100'>
                                                <Label className="form-label required-star" for="Enter OTP">
                                                    Enter OTP
                                                </Label>
                                                <InputNumberField value={formik.values.mobile_otp}
                                                    isRequired={true}
                                                    placeholder='OTP'
                                                    className={mobileVerified ? 'otp-verify-icon' : ''}
                                                    name='mobile_otp'
                                                    handleChange={formik.setFieldValue}
                                                    handleBlur={formik.setFieldTouched}
                                                    autoComplete='off'
                                                    maxLength={6}
                                                    disabled={mobileVerified}
                                                    errors={formik.errors.mobile_otp || (!mobileVerified && formik.values.mobile_otp?.length === 6 ? 'Please enter valid OTP!' : '')}
                                                    touched={formik.touched.mobile_otp}
                                                    onHoverTooltip={true}
                                                />
                                            </div>
                                            <div className='position-relative identix-send-otp-btn w-100'>
                                                <CustomButton type='button' color='primary' disabled={mobileOtpResendDisable} label='Resend' handleClick={() => resendOtp(1)} />
                                            </div>
                                        </div>
                                    </Col>
                                        {mobileOtpResendDisable && !mobileVerified ? <Col md='6' className='ps-0 mb-1 position-relative w-100'>
                                            Regenerate OTP after {mobileResendOtpRemainingTime} sec
                                        </Col> : ''}
                                    </>}
                                    {step === 1 && <div><CustomButton type='button' handleClick={handleGetOTP} disabled={formik.isSubmitting} color='primary' label='Get OTP' block /></div>}
                                    {step !== 1 && <Col sm='12' lg='12' className='mt-2'>
                                        <div className='gst-login-btn'>
                                            <CustomButton type='button' handleClick={handleGetOTP} disabled={formik.isSubmitting} color='primary' label='Submit' block loader={getOtpLoader} />
                                        </div>
                                    </Col>}
                                    <Col sm='12' lg='12'>
                                        <div className='gst-register-btn text-center my-3 mb-0'>
                                            <Button outline className='munim-button-link-none hover-link' onClick={() => (window.location.search ? history.push(`${CommonRouter.log_in}${history.location.search}`) : history.push(CommonRouter.log_in))}>
                                                Back to log in
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Col>
            </Row>
        </>
    )
}

export default ForgotPassword