const ValidationMessage = {
    is_require: 'Is required!',
    to_short: 'Too short!',
    to_long: 'Too long!',
    mn_not_valid: 'Mobile number is not valid!',
    pin_digit: 'Pincode must be 6 digit!',
    valid_pincode: 'Please enter valid pincode!',
    valid_email: 'Must be a valid email!',
    valid_password: 'Your password must be at least 6 characters. A recommended password is a combination of uppercase letters, lowercase letters, numbers, and symbols (!$@%).',
    signup_valid_password: 'Please follow password pattern!',
    valid_till: 'Quotation date is not greater than valid till date!',
    small_letter: 'Only alphabets is allow!',
    disc_in_percentage: 'Invalid percentage!',
    disc_percentage: 'Invalid %!',
    mrp_valid: 'Invalid mrp!',
    disc_in_amount: 'Discount amount should not be more then product/service value!',
    disc_amount: 'Amount of discount and payment must be equal to open balance!',
    tds_amount: 'Amount of tds and receipt must be equal to open balance!',
    otp_min_max: 'OTP is not valid!',
    pan_valid: 'Please enter valid PAN no.!',
    phn_valid: 'Please enter valid phone number!',
    fax_valid: 'Please enter valid fax number!',
    website_valid: 'URL is not valid!',
    gst_valid: 'Please enter valid GSTIN!',
    transporter_valid: 'Please enter valid transporter id!',
    pass_char: 'Password must be 6 to 16 character!',
    confirm_pass: 'Confirm password must match with password!',
    new_confirm_pass: 'New password and confirm passwords should be match!',
    future_date_allow: 'Future date is not allowed!',
    pd_past_date: 'Past date is not allowed!',
    vs_isx_valid: 'Please enter valid TIN!',
    rate_per: 'Rate must be between 0 to 100 percent (%)!',
    credit_period: 'Credit period days between 1 to 180!',
    fin_date: 'Please select a date of the current financial year!',
    prefix: 'Invalid prefix, Do not allowed to enter special characters (like / or -) in initial of prefix!',
    bill_prefix: 'Invalid bill number, Do not allowed to enter special characters (like /, - or ,) in initial of bill number!',
    len_zero: 'Length of leading zero must be 0-3!',
    gst_applicable_date_valid: 'GST applicable date must be after company establishment date!',
    item_code_valid: 'Special character not allowed!',
    hsn_sac_min: 'At least 4 digits are required!',
    ifsc_cap: 'IFSC code must be valid!',
    bill_no_prefix: 'Bill no should not start with zero!',
    invoice_prefix_valid: 'Invoice No. should not start with zero!',
    eway_applicable_date: 'Please enter E-Way applicable date!',
    lut_bond_no_req: 'Please enter LUT/Bond no.!',
    lut_bond_no_max: 'Maximum 20 character allowed!',
    lut_bond_from_date: 'Please enter LUT/Bond validity from date!',
    lut_bond_to_date: 'Please enter LUT/Bond validity to date!',
    vat_applicable_date_valid: 'VAT registration date must be after company establishment date!',
    outstanding_amount_valid: 'Outstanding amount 0 is not allow!',
    module_permission_valid: 'Please give at least one module permission to user!',
    otp_req: 'Please enter OTP!',
    op_stock_qty: 'Do not allow more than 7 digit and 3 decimal!',
    op_stock_value: 'Do not allow more than 10 digit and 2 decimal!',
    prefix_valid: 'No. should not start with zero!',
    eway_pincode: 'Please enter Pincode, while creating the e-way',
    suffix: 'You have entered an invalid special character, (Here allowed only special characters / or - ).',
    payment_amount: 'Paymment amount should be greater than 0.',
    items_valid: 'Comma is not allow in item name!'
}
export default ValidationMessage
export const notifyMessage = {
    cancel_eway_einvoice_message: 'Please select reason of cancelation!',
    cancel_date: 'Please select cancel date!',
    total_amount_not_be_zero: 'Total amount should not be zero!',
    nature_of_expense_req: 'Please select at least one nature of expense!',
    invoice_no_valid: 'Invoice number must be only 16 digits!',
    product_is_req: 'Please select at least one product!',
    account_req_for_primary_account: 'Please select account for primary data!',
    primary_account_req: 'Please select primary account!',
    credit_and_debit_total_same: 'Total of credit and debit must be same!',
    account_is_req: 'Please select at least one account!',
    consumption_production_total: 'Total amount of consumption and production should not be zero!',
    more_than_paid_amount: 'Do not allow more than paid amount!',
    amount_paid_not_be_zero: 'Total amount paid should not be zero!',
    valid_date: 'Please select valid date!',
    date_btn_establish_financial: 'Please select date between establish date to financial year end date!',
    bill_to_date_req: 'Please select bill to date!',
    bill_from_date_req: 'Please select bill from date!',
    quantity_of_product_req: 'Please add at least one quantity of product!',
    start_end_date_valid: 'Start date should be less than end date!',
    more_than_received_amount: 'Do not allow more than received amount!',
    received_amount_not_be_zero: 'Total received amount should not be zero!',
    invoice_to_date_req: 'Please select invoice to date!',
    invoice_from_date_req: 'Please select invoice from date!',
    export_sale_book_not_valid: 'Do not  select Exports Sales book, because party type is SEZ!',
    einvoice_bill_option_req: 'Please at least one option select!',
    delete_row_shortcut_work_on_challan: 'Attention, If Challan item selected, delete row (shortcut) not working!',
    vat_reg_date_valid: 'VAT registration date is not less then company establishment date!',
    cst_reg_date_valid: 'CST registration date is not less then company establishment date!',
    password_req: 'Please enter your password!',
    tcs_section_req: 'Please select any one TCS section!',
    login_signup_req: 'Please login/signup to perform this action!',
    return_without_invoice: 'You have not select any invoice item!',
    return_without_bill: 'You have not select any bill item!',
    email_excel: 'Exported data will send to your registered email shortly!',
    enter_valid_otp: '"Please enter valid OTP!',
    transaction_receipt_req: 'Please upload receipt before proceed!',
    otp_error_message: 'You are not allowed to save changes, first get OTP and complete the verification process!',
    select_product_service: 'Please select at least one product/service.',
    select_nature_of_expense: 'Please select at least one nature of expense.',
    select_account: 'Please select at least one account',
    total_amt_to_long: 'Entered amount is too big. (Allowed only max 10 digits.)',
    total_qty_to_long: 'Entered quantity is too big. (Allowed only max 10 digits.)',
    create_new_stock_no: 'New Batch/Lot no. will be create.',
    series_length: 'Document number length should not be more than 16 digits.',
    check_customer_create_inv_from_quo: 'Customer is inactive, you cannot generate invoice using this quotation.',
    check_customer_create_challan_from_quo: 'Customer is inactive, you cannot generate challan using this quotation.',
    check_customer_cancel_quo: 'customer is inactive, you cannot cancel this quotation.',
    check_customer_close_quo: 'customer is inactive, you cannot close this quotation.',
    check_items_cancel_quo: 'All items are inactive, we cannot allow you to cancel this quotation.',
    check_items_close_quo: 'All items are inactive, we cannot allow you to close this quotation.',
    check_vendor_create_PB: 'Vendor is inactive, you cannot generate bill using this purchase order.',
    check_items_create_SI_from_quo: 'All items are inactive, we cannot allow you to create a sales invoice using this quotation.',
    check_items_create_challan_from_quo: 'All items are inactive, we cannot allow you to create a challan using this quotation.',
    create_PB_from_PO: 'All items are inactive, we cannot allow you to create a purchase bill using this purchase order.',
    check_cutomer_copy_invoice: 'Customer is inactive, you cannot generate copy invoice using this sales invoice.',
    check_items_copy_invoice: 'All items are inactive, we cannot allow you to copy invoice using this sales invoice.',
    check_customer_create_SI_from_challan: 'Customer is inactive, you cannot generate sales invoice using this challan.',
    check_items_create_SI_from_challan: 'All items are inactive in this challan, we cannot allow you to create a sales invoice using this challan.',
    subscription_expire_0: 'Your current subscription has expired. Please renew to continue operation.',
    subscription_expire_1: 'Your current subscription has expired. Please contact your owner for renewal.',
    subscription_expire_2: 'Your current subscription has expired. Please contact your owner for renewal.',
    check_customer_create_receipt_from_SI: 'Customer is inactive, you cannot generate receipt using this sales invoice.',
    check_customer_sales_return_from_SI: 'Customer is inactive, you cannot generate sales return using this sales invoice.',
    check_customer_cancel_from_SI: 'Customer is inactive, you cannot cancel this sales invoice.',
    check_customer_file_attachment: 'Customer is inactive, you cannot file attachment this sales invoice.',
    check_items_create_receipt_from_SI: 'All items are inactive, we cannot allow you to generate receipt using this sales invoice.',
    check_items_create_sales_return_from_SI: 'All items are inactive, we cannot allow you to generate sales return using this sales invoice.',
    check_items_cancel_from_SI: 'All items are inactive, we cannot allow you to  cancel this sales invoice',
    check_items_file_attachment_from_SI: 'All items are inactive, we cannot allow you to  file attachment this sales invoice.',
    check_customer_cancel_sales_return: 'Customer is inactive, you cannot cancel this sales return.',
    check_items_cancel_sales_return: 'All items are inactive, we cannot allow you to cancel this sales return.',
    trial_expire_0: 'Your free trial period is over. Buy a subscription and enjoy your accounting operations.',
    trial_expire_1: 'Your free trial period is over. Contact to your owner for Buy a subscription.',
    check_vendor_create_bill_from_PO: 'Vendor is inactive, you cannot generate purchase bill using this purchase order.',
    check_vendor_approved_from_PO: 'Vendor is inactive, you cannot approved this purchase order.',
    check_vendor_rejected_from_PO: 'Vendor is inactive, you cannot rejected this purchase order.',
    check_vendor_cancel_from_PO: 'Vendor is inactive, you cannot cancel this purchase order.',
    check_vendor_copyPO_from_PO: 'Vendor is inactive, you cannot copy PO this purchase order.',
    check_vendor_manualclose_from_PO: 'Vendor is inactive, you cannot manual close this purchase order.',
    check_items_create_bill_from_PO: 'All items are inactive, we cannot allow you to generate purchase bill using this purchase order.',
    check_items_approved_from_PO: 'All items are inactive, we cannot allow you to approved this purchase order.',
    check_items_rejected_from_PO: 'All items are inactive, we cannot allow you to rejected this purchase order.',
    check_items_cancel_from_PO: 'All items are inactive, we cannot allow you to cancel this purchase order.',
    check_items_copyPO_from_PO: 'All items are inactive, we cannot allow you to copy PO this purchase order.',
    check_items_manualclose_from_PO: 'All items are inactive, we cannot allow you to manual close this purchase order.',
    check_vendor_create_payment_from_PB: 'Vendor is inactive, you cannot generate payment using this purchase bill.',
    check_vendor_purchase_return_from_PB: 'Vendor is inactive, you cannot generate purchase return using this purchase bill.',
    check_vendor_file_attachment: 'Vendor is inactive, you cannot file attachment this purchase bill.',
    check_items_create_payment_from_PB: 'All items are inactive, we cannot allow you to generate payment using this purchase bill.',
    check_items_purchase_return_from_PB: 'All items are inactive, we cannot allow you to generate purchase return using this purchase bill.',
    check_items_file_attachment_from_PB: 'All items are inactive, we cannot allow you to file attachment this purchase bill.',
    check_vendor_cancel_purchase_return: 'Vendor is inactive, you cannot cancel this purchase return.',
    check_items_cancel_purchase_return: 'All items are inactive, we cannot allow you to cancel this purchase return.',
    check_vendor_cancel_GJV: 'Vendor is inactive, you cannot cancel this GST journal voucher.',
    expense_paid_amount_message: 'Paid amount should not be greater than expenses total amount.',
    generate_eway_from_oth_country: 'Shipping Party must be within india for generate Eway bill, so try again with other party!'
}
export const popUpMessage = {
    discard_title: 'Unsaved changes!',
    discard_content: 'If you discard changes, you’ll delete any edits you made since you last saved.',
    cancel_content: 'Leaving this page will delete all unsaved changes.'
}
