
      import API from "!../node_modules/style-loader/dist/runtime/injectStylesIntoLinkTag.js";
      import insertFn from "!../node_modules/style-loader/dist/runtime/insertBySelector.js";
      import content from "!!../node_modules/file-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../node_modules/esbuild-loader/dist/index.cjs??ruleSet[1].rules[3].use[2]!./App.css";
      

var options = {};


      options.insert = insertFn.bind(null, "head");
    

var update = API(content, options);



export default {}