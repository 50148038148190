import React from 'react'
import { Col, Row } from 'reactstrap'
import gstLogin from '../assets/img/gstlogin.svg'
import gstLogo from '../assets/img/logo.svg'
import CustomButton from '../common_component/custom_field/CustomButton'
import InputPasswordField from '../common_component/custom_field/InputPasswordField'
import Hotkeys from 'react-hot-keys'
import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import ValidationMessage from '../common_component/Validation'
import CommonApiEndPoint from '../helper/commonApiEndPoint'
import CommonRouter from '../helper/commonRoute'
import { ApiCall } from '../helper/axios'
import { useHistory } from 'react-router-dom'
import * as Yup from "yup"
import useNotify from '../custom_hooks/useNotify'

function ResetPassword() {
    // ** Hooks
    const history = useHistory()
    const notify = useNotify()
    const location_state = history.location.state.verifyToken;
    const SignupSchema = Yup.object().shape({
        password: Yup.string().trim()
            .required(ValidationMessage.is_require)
            .min(6, ValidationMessage.signup_valid_password),
        confirm_password: Yup.string().trim()
            .required(ValidationMessage.is_require)
            .oneOf([Yup.ref('password'), null], ValidationMessage.confirm_pass)
    })
    const handleResetPassword = async (value) => {
        const data = {
            password: value.password,
            confirm_password: value.confirm_password
        }
        const url = `${CommonApiEndPoint.reset_password}?token=${location_state}`
        const res = await ApiCall('POST', url, data)
        if (res.data.status === 'success' && res.data.statusCode === 200) {
            history.replace(window.location.search ? `${CommonRouter.log_in}${history.location.search}` : CommonRouter.log_in)
            notify(res.data.message, 'success')
            // dispatch(setUserVerificationError({ error: 'success', message: res.data.message }))
        } else {
            history.replace(window.location.search ? `${CommonRouter.log_in}${history.location.search}` : CommonRouter.log_in)
            notify(res.data.message, 'error')
            // dispatch(setUserVerificationError({ error: 'error', message: res.data.message }))
        }
    }
    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema: SignupSchema,
        onSubmit: (value) => {
            handleResetPassword(value)
        }
    })
    const onKeyDown = (keyName, e) => {
        e.preventDefault()
        if (formik.dirty && e.keyCode === 13) {
            formik.handleSubmit()
        }
    }
    return (
        <>
            <Helmet>
                <title>Password Recovery for Secure Accounting- Munim</title>
                <meta property="og:title" content="Password Recovery for Secure Accounting- Munim" />
                <meta name="description" content="Ensure the security of your financial data with Munim's password reset feature. Safely reset your password and regain access to your account." />
            </Helmet>
            <Hotkeys keyName="enter" onKeyDown={onKeyDown} filter={() => true}></Hotkeys>
            <Row className='auth-inner m-0'>
                <Col className='d-none d-lg-flex align-items-center justify-content-center p-5' lg='5' sm='12' >
                    <div className='gst-login-wrapper text-center'>
                        <h1 className='gst-login-text py-5'>Where you want to go...</h1>
                        <div className='gst-login-img'>
                            <img src={gstLogin} width={'700px'} />
                        </div>
                    </div>
                </Col>
                <Col className=' d-flex align-items-center justify-content-center munim-bg-white p-4 gst-resp-color' lg='7' sm='12'>
                    <Col className='gst-responsive-width d-lg-flex align-items-center justify-content-center m-auto' sm='8' lg='10' xl='7'>
                        <div className='gst-login-card-container gst-card p-4 px-5'>
                            <div className='gst-login-logo text-center py-5 pt-4'>
                                <img src={gstLogo} />
                            </div>
                            <div className='gst-welcome-text'>
                                <h2>Reset Password</h2>
                                <p>Your new password must be different from previously used passwords</p>
                            </div>
                            <div className="gst-login-form">
                                <Row>
                                    <Col sm='12' lg='12'>
                                        <div className='position-relative mb-3'>
                                            <InputPasswordField
                                                label='New Password'
                                                placeholder="Password"
                                                name="password"
                                                maxLength='16'
                                                isRequired={true}
                                                value={formik.values.password}
                                                handleBlur={formik.setFieldTouched}
                                                handleChange={formik.setFieldValue}
                                                errors={formik.errors.password}
                                                touched={formik.touched.password}
                                            />
                                        </div>
                                        <div className="error-msg-block mb-1 p-1">
                                            <p>Your password must be at least 6 characters. A recommended password is a combination of uppercase letters, lowercase letters, numbers, and symbols (!$@%).</p>
                                        </div>
                                    </Col>
                                    <Col sm='12' lg='12'>
                                        <div className='position-relative mb-3'>
                                            <InputPasswordField
                                                label='Confirm New Password'
                                                placeholder="Confirm Password"
                                                name="confirm_password"
                                                maxLength='16'
                                                isRequired={true}
                                                value={formik.values.confirm_password}
                                                handleBlur={formik.setFieldTouched}
                                                handleChange={formik.setFieldValue}
                                                errors={formik.errors.confirm_password}
                                                touched={formik.touched.confirm_password}
                                            />
                                        </div>
                                    </Col>
                                    <Col sm='12' lg='12'>
                                        <div className='gst-login-btn'>
                                            <CustomButton type='button' handleClick={formik.handleSubmit} color='primary' block label='Set New Password' />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Col>
            </Row>
        </>
    )
}

export default ResetPassword