const CommonRouter = {
    log_in: '/log-in',
    sign_in: '/sign-in',
    sign_up: '/sign-up',
    admin_login: '/admin-login',
    forgot_password: '/forgot-password',
    reset_password: '/reset-password',
    active_email: '/active-email',
    verify_email_preference: '/verify-email-preference',
}
export default CommonRouter
