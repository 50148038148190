import React, { useRef } from 'react'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
export default function SupportChat({ }) {
    const tawkMessengerRef = useRef()

    /**
     * IW0079
     * This function is call onload of tawk chat board.
     */
    const onLoad = () => {
        setTimeout(() => {
            document.querySelector(".widget-visible iframe").contentWindow.document.getElementsByTagName('div')[0].querySelector('.tawk-min-container .tawk-button-circle.tawk-button-large').style = "width:40px;height:40px"

            document.querySelector(".widget-visible iframe").contentWindow.document.getElementsByTagName('div')[0].getElementsByTagName('svg')[0].setAttribute('height', '25px')
            window.Tawk_API.minimize()
        }
            , 2000)
    }
    const onChatMinimized = () => {
        setTimeout(() => {
            document.querySelector(".widget-visible iframe").contentWindow.document.getElementsByTagName('div')[0].querySelector('.tawk-min-container .tawk-button-circle.tawk-button-large').style = "width:40px;height:40px"

            document.querySelector(".widget-visible iframe").contentWindow.document.getElementsByTagName('div')[0].getElementsByTagName('svg')[0].setAttribute('height', '25px')
        }
            , 100)
    }

    const customStyle = {
        visibility: {
            mobile: {
                xOffset: -16,
                yOffset: -10,
                position: 'br'
            }
        }
    }
    return (
        <div>
            <TawkMessengerReact
                onLoad={onLoad}
                onChatMinimized={onChatMinimized}
                customStyle={customStyle}
                propertyId={'6284e229b0d10b6f3e72d47d'}
                widgetId={'1g3bh6tt2'}
                ref={tawkMessengerRef} />
        </div>
    )
}
